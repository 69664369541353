import { useEffect,useState } from "react"


export const useMemberProfileHook = (pageNumber,id)=>{
 const [memberPlayHistory,setMemberPlayHistory] = useState({results:[]})
 const [loadingSpinner,setLoadingSpinner] = useState(true)
 const [error,setError] = useState(false)
 const [hasMore,setHasMore] = useState(false)


async function fetchMemberPlayHistory(){
    setLoadingSpinner(true)
    setError(false)
    const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/userprofile/member/${id}/stakes?page=${pageNumber}`)
    
    data.json().then((response,reject)=>{ 
        setLoadingSpinner(false)        
        setMemberPlayHistory({...memberPlayHistory,...response,results:[...memberPlayHistory.results,...response.results]})
        //setStakeData({...stakeData,...response,results:[...response.results]})
        setHasMore(response.next !== null)        
        setLoadingSpinner(false)
        
    }).catch((error)=>{
        setError(true)
    }) 
}

useEffect(()=>{    
 fetchMemberPlayHistory()
},[pageNumber])

    return {loadingSpinner,error,memberPlayHistory,hasMore}
}
