import { useEffect,useState } from "react"


export const useRankingsHook = (compId,pageNumber,timeFilter)=>{
 const [stakeData,setStakeData] = useState({results:[]})
 const [loadingSpinner,setLoadingSpinner] = useState(true)
 const[error,setError] = useState(false)
 const [hasMore,setHasMore] = useState(false)

useEffect(()=>{ 
    stakeData.results.length = 0 
}, [compId,timeFilter]) 



async function fetchRankingData(){
    
    setLoadingSpinner(true)
    setError(false)
    const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/rankings?page=${pageNumber}&competition=${compId}&time_filter=${timeFilter}`)
    
    data.json().then((response,reject)=>{ 
        setLoadingSpinner(false)        
        setStakeData({...stakeData,...response,results:[...stakeData.results,...response.results]})
        console.log(response)
        //setStakeData({...stakeData,...response,results:[...response.results]})
        setHasMore(response.next !== null)        
        setLoadingSpinner(false)
        
    }).catch((error)=>{
        setError(true)
        console.log(error)
    }) 
}

useEffect(()=>{    
 fetchRankingData() 
},[compId,pageNumber,timeFilter])

    return {loadingSpinner,error,stakeData,hasMore}
}
