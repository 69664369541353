import { useEffect,useState } from "react"


export const usePlayersHook = (pageNumber,id)=>{
 const [playerData,setPlayerData] = useState({results:[]})
 const [loadingSpinner,setLoadingSpinner] = useState(true)
 const [error,setError] = useState(false)
 const [hasMore,setHasMore] = useState(false)


async function fetchPlayersHistory(){
    setLoadingSpinner(true)
    setError(false)
    const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/event/${id}/stakers?page=${pageNumber}`)
    
    data.json().then((response,reject)=>{ 
        setLoadingSpinner(false)        
        setPlayerData({...playerData,...response,results:[...playerData.results,...response.results]})
        //setStakeData({...stakeData,...response,results:[...response.results]})
        setHasMore(response.next !== null)        
        setLoadingSpinner(false)
        
    }).catch((error)=>{
        setError(true)
    }) 
}

useEffect(()=>{    
 fetchPlayersHistory()
},[pageNumber])

    return {loadingSpinner,error,playerData,hasMore}
}
