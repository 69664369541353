import React,{useState} from "react"
import {Nav,Navbar,Container,Button,Modal,NavDropdown,Offcanvas} from "react-bootstrap"
import { BsFillPersonFill,BsHouseDoorFill,BsBookFill,BsAwardFill,BsListCheck} from "react-icons/bs";
import { useDispatch,useSelector } from "react-redux"
import { Link,useNavigate } from "react-router-dom";
import { login,logout } from "../../redux/reducers/authSlice";
import { clearUserProfile,addUserProfile} from "../../redux/reducers/userProfileSlice";
import "./header.css"
import { GoogleLogin } from "@react-oauth/google";
import { username_truncate } from "../utils/users_utils";

  function LogoutConfirmModal(props){
    const dispatch = useDispatch()
  
   return (<Modal {...props}>
  <Modal.Header closeButton>
    <Modal.Title>Logout</Modal.Title>
  </Modal.Header>

  <Modal.Body>
    <p>Are you sure you want to leave out?</p>
  </Modal.Body>

  <Modal.Footer>
    <Button variant="danger" onClick={()=>props.logoutClicked()}>Logout</Button>
    <Button variant="primary" onClick={props.cancelClicked}>Cancel</Button>
  </Modal.Footer>
</Modal>)
  }

const Header = ()=>{
 const [logoutConfirmShow,setShowLogoutModal] = useState(false)

 const navigate = useNavigate()

 const token = useSelector(state=>state.auth.value.token) 
 
 const dispatch = useDispatch()
 const user = useSelector((state)=>state.auth.value.user);
 const userProfile = useSelector((state)=>state.userProfile.value)
 const isLoggedIn = useSelector((state)=>state.auth.value.isLoggedIn); 

 const expand = 'md'

 function logoutClicked(){
  dispatch(logout());
  dispatch(clearUserProfile());
  setShowLogoutModal(false);
  //navigate("/users/login"); 
}

async function googleLoginSuccess(res){
  
  console.log("google login->",res);
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/google-login`,{method:"POST",mode:"cors", body: JSON.stringify(res?.credential),
           "headers":{"Content-Type":"application/json"}})   
   if(response.status === 200){
    response.json().then((data)=>{
      dispatch(login(data))
      //dispatch(addUserProfile(data.user))
      fetchUserProfileData(data?.token);
      console.log("login->",data);
    })   
   }        
 
}

const fetchUserProfileData = async (res_token) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/userprofile/`,{
    headers:{"content-Type":"application/json","Authorization":"Bearer "+res_token},method:"GET",mode:"cors"})
    if(response.status === 401){
    // dispatch(logout())
    }else{
   response.json().then(res=>{   
   dispatch(addUserProfile(res))
   console.log("user",res)
    })
  }
  }

function googleLoginError(){
  console.log("login failed");
}

    return (<>       
          <header>
        <nav class="top-nav">
          <div class="top-nav-items">
          <Navbar.Brand as={Link} to="/">
            <span className="brand-text">PointsRank</span> 
            <small className="brand-sentence-text">Predict football matches and events!</small>
            </Navbar.Brand>          

           <div class="top-nav-item">
            <div class="button-group">
                {!isLoggedIn?
                <Nav>                  
                <Nav.Link as={Link} to="/users/login"><span className="login-text">Login</span></Nav.Link>
                <Nav.Link as={Link} to="/users/register"><span className="register-text">Register</span></Nav.Link>
           </Nav>:
                 <Nav>          
                 <NavDropdown className="nav-dropdown" title={userProfile?.stake_data != undefined && `${username_truncate(10,user?.username)}`} id="basic-nav-dropdown">           
                 <NavDropdown.Item as={Link} eventKey="7" to="/profile">Profile</NavDropdown.Item>      
                 <NavDropdown.Item as={Link} eventKey="8" to="/stake_history">Stake History</NavDropdown.Item>
                 <NavDropdown.Divider />
                 <NavDropdown.Item as={Link} eventKey="9" to="/account-settings">Account Settings</NavDropdown.Item>
                 <NavDropdown.Item onClick={()=>setShowLogoutModal(true)}>Logout</NavDropdown.Item>
                 </NavDropdown>
           
                  {userProfile != null?
                  <img
                  alt="img"
                  src={`${process.env.REACT_APP_BACKEND_URL}${userProfile?.photo_url}`}
                  width="30"
                  height="30"
                  className="mt-1 rounded-circle"
                  />:<></>}          
                  </Nav>
                }
            </div>
           </div>
          </div>
        </nav>  

          <nav class="mid-nav">
          <div class="mid-nav-items">   

           {isLoggedIn?
            <div class="mid-nav-item points">
              <span className="user-points-text">
                {`${userProfile !== null && userProfile.stake_data !== undefined && userProfile?.stake_data.total_points} pts`}
                </span>
                </div>:<div class="top-nav-item points"><span className="user-points-text">
                {`0.00 pts`}
                </span></div>}     
               
          </div>
        </nav>                                     
                  
        <Navbar key={expand} collapseOnSelect expand={expand} className="mb-3 sticky-top">
          <Container>            
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              className="offcanvas-dropdown"
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"              
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Menu
                </Offcanvas.Title>
              </Offcanvas.Header>
              
              <Offcanvas.Body>
              <Nav className="justify-content-center flex-grow-1 pe-3 nav-btn">               
              <Nav.Link as={Link} to="/" eventKey="1"><BsHouseDoorFill size={20}/> Home</Nav.Link>
              <Nav.Link as={Link} to="/learn-more" eventKey="2"><BsBookFill size={20}/> Learn more</Nav.Link>           
              <Nav.Link as={Link} to="/rankings" eventKey="3"><BsAwardFill size={20}/> Rankings</Nav.Link>           
              <Nav.Link as={Link} to="/members" eventKey="4"><BsFillPersonFill size={22}/> Members</Nav.Link>   
                      
               {/* <Nav.Link as={Link} to="/forum"><BsFillChatLeftFill size={20}/> Forum</Nav.Link> 
              <Nav.Link as={Link} to="/wager"><BsArrowDownLeft size={20}/> Wager</Nav.Link>
              <Nav.Link as={Link} to="/your-wager">Your Wagers</Nav.Link> */} 
              {user?.is_staff?
              <><Nav.Link as={Link} to="/grader" eventKey="5"><BsListCheck/> Grader</Nav.Link>
              <Nav.Link as={Link} to="/admin" eventKey="6">Admin</Nav.Link>
              <Nav.Link as={Link} to="/admin-events" eventKey="7">Admin Events</Nav.Link>
              <Nav.Link as={Link} to="/admin-events-choices" eventKey="8">Resolve Events</Nav.Link>
              </>
              :<></>}   
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas> 
          </Container>
        </Navbar>    
                       
               
            
      
               
        </header>
       <LogoutConfirmModal show={logoutConfirmShow} onHide={()=>setShowLogoutModal(false)} logoutClicked={()=>logoutClicked()} cancelClicked={()=>setShowLogoutModal(false)}/>
      </>)
}

{/* <Navbar key={expand} bg="light" collapseOnSelect expand={expand} className="mb-3 sticky-top">
          <Container>
             <Navbar.Brand as={Link} to="/"><img width={150} height={30} src={`${process.env.REACT_APP_BACKEND_URL}/static/logos/pointsrank.png`}/></Navbar.Brand> 
            <Navbar.Brand as={Link} to="/">Points Rank</Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Points Rank
                </Offcanvas.Title>
              </Offcanvas.Header>
              
              <Offcanvas.Body>
                <Nav className="justify-content-start flex-grow-1 pe-3">               
              <Nav.Link as={Link} to="/" eventKey="1"><BsHouseDoorFill size={20}/> Home</Nav.Link>
              <Nav.Link as={Link} to="/learn-more" eventKey="2"><BsBookFill size={20}/> Learn more</Nav.Link>           
              <Nav.Link as={Link} to="/rankings" eventKey="3"><BsAwardFill size={20}/> Rankings</Nav.Link>           
              <Nav.Link as={Link} to="/members" eventKey="4"><BsFillPersonFill size={22}/> Members</Nav.Link>   
                      
              {/*  <Nav.Link as={Link} to="/forum"><BsFillChatLeftFill size={20}/> Forum</Nav.Link> 
              <Nav.Link as={Link} to="/wager"><BsArrowDownLeft size={20}/> Wager</Nav.Link>
              <Nav.Link as={Link} to="/your-wager">Your Wagers</Nav.Link> 
              {user?.is_staff?
              <><Nav.Link as={Link} to="/grader" eventKey="5"><BsListCheck/> Grader</Nav.Link>
              <Nav.Link ad={Link} to="/admin" eventKey="6">Admin</Nav.Link></>
              :<></>}   
                </Nav>   
                {!isLoggedIn?
      <Nav>
         <Button onClick={()=>navigate("/users/login")}>Login</Button>{' '}
         <Nav.Link as={Link} to="/users/register">Register</Nav.Link>
    </Nav>:
       <Nav>          
       <NavDropdown title={userProfile?.stake_data != undefined && `${user?.username} (${userProfile?.stake_data.total_points} pts)`} id="basic-nav-dropdown">           
       <NavDropdown.Item as={Link} eventKey="7" to="/profile">Profile</NavDropdown.Item>      
       <NavDropdown.Item as={Link} eventKey="8" to="/stake_history">Stake History</NavDropdown.Item>
       <NavDropdown.Divider />
       <NavDropdown.Item as={Link} eventKey="9" to="/account-settings">Account Settings</NavDropdown.Item>
       <NavDropdown.Item onClick={()=>setShowLogoutModal(true)}>Logout</NavDropdown.Item>
       </NavDropdown>
 
        {userProfile != null?
        <img
        alt="img"
        src={`${process.env.REACT_APP_BACKEND_URL}${userProfile?.photo_url}`}
        width="30"
        height="30"
        className="mt-1 rounded-circle"
        />:<></>}          
        </Nav>         
 }              

              </Offcanvas.Body>
            </Navbar.Offcanvas> 
          </Container>
        </Navbar> */}
export default Header

