import React from "react";
import { Outlet,useNavigate } from "react-router-dom";
import Footer from "../footer/Footer";

const OuterPage = ()=>{
 
   
    return (<>    
    <Outlet/>
    <Footer/></>)
}

export default OuterPage