
import './App.css';
import { useEffect } from 'react';
import {BrowserRouter,Route,Routes} from "react-router-dom";
import Login from './components/login/Login';
import Register from './components/register/Register';
import Rankings from './components/rankings/Rankings';
import Homepage from './components/homepage/Homepage';
import EventDetail from './components/events/EventDetail';
import StakersList from './components/event-stakers/StakersList';
import Profile from './components/user-profile/Profile';
import Member from './components/members/Members';

import UserStakeHistory from './components/user-stake-history/UserStakeHistory';
import Grader from './components/grader/Grader';

import MemberProfile from './components/member-profile/MemberProfile';
import CompetitionDetail from './components/competition/CompetitionDetail';
import MainPage from './components/home-router-page/MainPage';
import CompetitionInfo from './components/competition/CompetitionInfo';
import CompetitionList from './components/competition/CompetitionList';
import UserActivate from './components/user-activate/UserActivate';
import ResetPasswordForm from './components/forgot-password/ResetPasswordForm';
import ForgotPassword from './components/forgot-password/ForgotPassword';
import ChangePassword from './components/change-password/ChangePassword';
import LearnMore from './components/learn-more/LearnMore';
import AccountSettings from './components/account-settings/AccountSettings';
import PrivateRoutes from './components/utils/PrivateRoutes';
import OuterPage from './components/outer-page/OuterPage';
import NotFound from './components/page-not-found/NotFound';
import ContactPage from './components/contact-page/ContactPage';
import PrivacyPolicy from './components/privacy-policy/PrivacyPolicy';
import Terms from './components/terms/Terms';
import Admin from './components/custom-admin/Admin';
import AdminEvents from './components/custom-admin/AdminEvents';
import AdminEventChoices from './components/custom-admin/AdminEventChoices';
import ScrollToTop from './components/outer-page/ScrollToTop';



//redux will be the client managed store

function App() {

  return (<BrowserRouter> 
  <ScrollToTop/>
  <Routes>
   <Route exact path="/users" element={<OuterPage/>}>
    <Route exact path="/users/login" element={<Login/>}></Route>
    <Route exact path="/users/register" element={<Register/>}></Route> 
    <Route exact path="/users/activate_account/:uid/:token" element={<UserActivate/>}></Route>
    <Route exact path="/users/register" element={<Register/>}></Route>     
    <Route exact path="/users/forgot-password" element={<ForgotPassword/>}></Route>
    <Route exact path="/users/reset-password/:uid/:token" element={<ResetPasswordForm/>}></Route>
    </Route>
    <Route exact path="" element={<MainPage/>}>
      {/* inside mainpage */}
    <Route exact path="/" element={<Homepage/>}>
      {/*inside homepage*/}
    <Route exact path="" element={<CompetitionList/>}></Route>
    <Route exact path="competitions/:id/:slug" element={<CompetitionInfo/>}></Route>
    </Route>  
   <Route exact path="/event/:id/:slug" element={<EventDetail/>}/>
  <Route exact path="/event/:id/:slug/stakers" element={<StakersList/>}></Route>  
  <Route exact path="/members" element={<Member/>}></Route>
  <Route exact path="/rankings" element={<Rankings/>}></Route>
  <Route exact path="/contact" element={<ContactPage/>}></Route>
  <Route exact path="/privacy-policy" element={<PrivacyPolicy/>}></Route>
  <Route exact path="/terms" element={<Terms/>}></Route>
  {/* <Route exact path="/forum" element={<ForumHome/>}></Route>
  <Route exact path="/forum/:id" element={<ForumPost/>}></Route>  */}   
  <Route exact path="/member/:id/:slug" element={<MemberProfile/>}></Route>
  <Route exact path="/competition/:id/:slug" element={<CompetitionDetail/>}></Route>
  <Route exact path="/learn-more" element={<LearnMore/>}></Route>
  <Route exact path="*" element={<NotFound/>}></Route>
   
  <Route element={<PrivateRoutes/>}>
  <Route exact path="/account-settings" element={<AccountSettings/>}></Route>
  <Route exact path="/change-password" element={<ChangePassword/>}></Route>
  <Route exact path="/stake_history" element={<UserStakeHistory/>}></Route>
  <Route exact path="/profile" element={<Profile/>}></Route>
  <Route exact path="/grader" element={<Grader/>}></Route>  
  <Route exact path="/admin" element={<Admin/>}></Route>
  <Route exact path="/admin-events" element={<AdminEvents/>}></Route>
  <Route exact path="/admin-events-choices" element={<AdminEventChoices/>}></Route>
 {/*  <Route exact path="/wager" element={<Wager/>}></Route>
  <Route exact path="/your-wager" element={<UserWager/>}></Route> */}

  </Route>
   
     {/* inside mainpage end */}
    </Route>            
    </Routes>    
  </BrowserRouter>  
  );
}

export default App;
