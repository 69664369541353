import React from "react";
import { useNavigate} from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import "./PlayableEventChoice";
import {BsFillClockFill,BsFillChatRightTextFill,BsFillPersonFill} from "react-icons/bs";
import {Spinner } from "react-bootstrap";

import "./event_choice.css";
import PlayableEventChoice from "./PlayableEventChoice";

const PlayableEventView = ({event,increment,loadingSpinner})=>{

   const userEventChoice1 = useSelector((state)=>state.userChoice.value)
   const user = useSelector((state)=>state.auth.value.user) 
   //const userProfile = useSelector((state)=>state.userProfile.value)
   const token = useSelector(state=>state.auth.value.token)
   const dispatch = useDispatch();
   const currentUserId = user.id 

   const navigate = useNavigate();   

       /* input - event, output - event and choice the user staked on */
    function filterByEvent(){
      if(userEventChoice1?.length > 0){
        return userEventChoice1.filter(item=>item.event.id === event.id);
      }
     }
        //check closing time
        function checkClosingTime(event){
          if(event.closing_type === "t"){
          const closingTime = new Date(event.closing_time);
          const dateNow = new Date()
         
           if(dateNow > closingTime){
             return true
           }
           return false
         }else{
           return event.is_closed
         }
          }


      function formatClosingTime(date){
       const newDate = new Date(date);
        //return `${newDate.getUTCHours()}:${newDate.getUTCMinutes()}:${newDate.getUTCSeconds()}`;
        return newDate.toUTCString()
      }

       //this is used for the blue border on a choice if selected before
   function checkSelectedChoice(choice){
    const userEventChoice = filterByEvent()
    if(userEventChoice?.length > 0){
     /* if the choice in userEventChoice is same as one of the choices 
     in the event then return true if no match return false */          
      if(userEventChoice[0].choice.id === choice.id){
       return true
     }else{
       return false
     }
    }else{
     return false
    }  
} 

    /* get all the stakes from this particular user  */
 /*    async function fetchUserEventChoice(){    
      const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/stake`,
      {headers:{'Authorization':"Bearer "+token}})
       if(data.status === 401){
  
       }else{
      await data.json().then((response,failure)=>{
          console.log("userEvent->",response);        
          //setUserEventChoice(response);       
          dispatch(choosen(response))
      })
    }
     } */

    return (<div style={{width:"inherit"}} className="border-bottom">
    <div>
        <div className="d-flex justify-content-center m-1 playable-event-desc-item link" onClick={()=>navigate(`/event/${event.id}/${event.slug}`)}>
          <span className="event-question">{event.event_question}</span>          
        </div>  
        <div className="d-flex justify-content-center">{loadingSpinner?<Spinner animation="grow" size="sm" />:<></>}</div>
            <div className="d-flex justify-content-center flex-row"> 
             <div className="overflow-scroll  event-choice-container">
              <div className="d-flex ">
            {event.choices.map((choice)=>{
              return (
                <div key={choice.id} className="m-1 flex-shrink-0">
                <PlayableEventChoice
                 key={choice.id}                
                 choice={choice} 
                 selected={checkSelectedChoice(choice)}
                 onClick={()=>increment(choice,event)}
                 />
                 </div>
                 
              )
            })}
            
            </div></div></div>
            <div className="playable-event-desc">
              {event.closing_type === "t"?
              <div className="playable-event-desc-item"><span className="closing-time" style={checkClosingTime(event)?{color:"red"}:{color:"black"}}><BsFillClockFill size={18}/> {formatClosingTime(event.closing_time)}</span></div>
             :<div className="playable-event-desc-item"><span className="closing-time" style={checkClosingTime(event)?{color:"red"}:{color:"black"}}><BsFillClockFill size={18}/> {event.is_closed?"Closed":" Still Open"}</span></div>}
              <div className="playable-event-desc-item pointer" onClick={()=>navigate(`/event/${event.id}/${event.slug}`)}><span><BsFillChatRightTextFill size={18}/> {event.commentCount}</span></div>
              <div className="playable-event-desc-item pointer" onClick={()=>navigate(`/event/${event.id}/${event.slug}/stakers`)}><span><BsFillPersonFill size={18}/> {event.stake_count}</span></div>
              </div>

    </div>        
  </div>)
}

export default PlayableEventView