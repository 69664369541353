import React,{useState,useEffect} from "react";
import { Card,Spinner } from "react-bootstrap";
import Poll from "../polls/Poll";

import "./competition.css";

const CompetitionList = ()=>{
    
    const [competitions, setCompetitions] = useState([])
    const [loadingCompetition,setLoadingCompetition] = useState(false)    
    
    useEffect(()=>{        
        fetchCompetitions(); 
      },[])

    async function fetchCompetitions(){
        setLoadingCompetition(true)
        const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/competitions`)
        data.json().then(response=>{
            setLoadingCompetition(false)
            setCompetitions(response)
            console.log("competitions",response);            
        })
    }
    return(<>

        {loadingCompetition?<div className="d-flex justify-content-center p-2"><Spinner animation="border" role="status">
           <span className="visually-hidden">Loading...</span>
             </Spinner></div>:
        competitions.length > 0?
        competitions.map((comp)=>{
            return (<Card className="mb-3" key={comp.id}>
                <Card.Header className="card-header">
                    <img width="42" height="42" alt="-" src={`${process.env.REACT_APP_BACKEND_URL}${comp.logo}`}/><span className="competition-name">{comp.name}</span>
                </Card.Header>
                <Poll compId={comp.id} slug={comp.slug}/>                
                </Card>)         
        }):<p>No Competitions</p> 
        }
    </>)
}

export default CompetitionList