import {createSlice} from "@reduxjs/toolkit"

const initialState = null

const userChoiceSlice = createSlice({
    name:"userChoice",
    initialState:{value:initialState},
    reducers:{
        choosen:(state,action)=>{
            state.value = action.payload
        }     
    }
})
const userChoiceReducer = userChoiceSlice.reducer;
export const {choosen} = userChoiceSlice.actions;
export default userChoiceReducer;

