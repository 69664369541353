import { useEffect,useState } from "react"


export const useAllPollsHook = (pageNumber,id)=>{
 const [polls,setPolls] = useState({results:[]})
 const [loadingSpinner,setLoadingSpinner] = useState(true)
 const [error,setError] = useState(false)
 const [hasMore,setHasMore] = useState(false)


async function fetchAllPolls(){
    setLoadingSpinner(true)
    setError(false)
    const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events?compId=${id}&page=${pageNumber}`)
    
    data.json().then((response,reject)=>{ 
        setLoadingSpinner(false)        
        setPolls({...polls,...response,results:[...polls.results,...response.results]})        
        //setStakeData({...stakeData,...response,results:[...response.results]})
        setHasMore(response.next !== null)        
        setLoadingSpinner(false)
        
    }).catch((error)=>{
        setError(true)
    }) 
}

useEffect(()=>{    
 fetchAllPolls()
},[pageNumber])

    return {loadingSpinner,error,polls,hasMore}
}
