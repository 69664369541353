import React, { useEffect,useState} from "react"
import { useParams,useNavigate } from "react-router-dom"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import Spinner from "react-bootstrap/Spinner"
import  Button from "react-bootstrap/Button"
import { DropdownButton,Dropdown } from "react-bootstrap"
import AllPolls from "../polls/AllPolls"

import {BsFillArrowLeftCircleFill} from "react-icons/bs"

const CompetitionDetail = ()=>{
   const {id} = useParams()
   const [competition,setCompetition] = useState({})
   const [seasons,setSeasons] = useState([])
   const [loadingCompetition,setLoadingCompetition] = useState(false)
   const [season,setSeason] = useState({}) //to add seaaons later
      
   const navigate = useNavigate()
   
    async function fetchCompetition(){
        setLoadingCompetition(true)
        const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/competitions/${id}`)
        if(data.status === 404){
            navigate("/not-found")            
        }else{
        data.json().then(response=>{
            setLoadingCompetition(false)
            setCompetition(response)
            console.log("compaa",response)            
        })
    }        
    } 
    
    async function fetchSeasons(){
        const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/seasons`);
        data.json().then((res)=>{
           setSeasons(res)          
        })  
       }

    useEffect(()=>{
     fetchCompetition()
     fetchSeasons()
    },[])

    return (
        <Container>            
            <Button size="sm" className="mt-5 rounded-pill" onClick={()=>navigate(-1)}>       
        <BsFillArrowLeftCircleFill size={15}/> Back</Button>
        <Row className="mt-1">
            <Col md={8}>
                <div className="d-flex justify-content-around">
            <span className="text-muted">All Events for {competition.name}</span>
        <DropdownButton 
        className="mr-2"              
        size="sm"
        variant="secondary"
        title={"Time"}>
            {seasons.length >0?
        seasons.map(season=>{
            return(
                <Dropdown.Item key={season.id}>{season.title}</Dropdown.Item>
            )
        }): <Dropdown.Item>No Season</Dropdown.Item>    
        }       
        
           
    </DropdownButton>
    </div>
    </Col>
    </Row>
    
            <Row>
                <Col md={8}>
            {loadingCompetition?<Spinner className="mt-5" animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner>:        
            competition &&
        <Card className="mt-3">
            <Card.Header>
            <img width="32" height="32" alt="-" src={`${process.env.REACT_APP_BACKEND_URL}${competition.logo}`}/>{competition.name}
            </Card.Header>
            <Card.Body> 
                <AllPolls compId={id} pollCount={competition.poll_count}/>            
            </Card.Body>
        </Card>
            }
        </Col>
        <Col>     
        </Col>
        </Row>
        </Container>
    )
}

export default CompetitionDetail