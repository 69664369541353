import React,{useState,useEffect} from "react"
import PlayableEventView from "./PlayableEventView";
import { useSelector,useDispatch } from "react-redux";
import { choosen } from "../../redux/reducers/userChoiceSlice";
import { Modal,Button} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate} from "react-router-dom";

/* i passed the poll id as a prop down to its child component eventList, so
the the child component can use the id to fetch events that belong to the 
specific poll */
const PlayableEventList = ({id,compId})=>{
  const [events,setEvents] = useState([]);  
  const [playableEvents,setPlayableEvents] = useState();
  const [userEventChoice,setUserEventChoice] = useState([]);
  
  const user = useSelector((state)=>state.auth.value.user) 
  //const events = useSelector((state)=>state.event.value)
  const token = useSelector(state=>state.auth.value.token)

  const currentUserId = user.id 
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [loadingSpinner,setLoadingSpinner] = useState(false);

    //modal stuff
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  
  useEffect(()=>{
    if(id !== undefined){
    fetchEventsByPoll(id)
    }
    fetchUserEventChoice();
  },[])

 useEffect(()=>{
  if(compId !== undefined){     
    fetchEventsNoPoll(compId)
  }
 },[])
      
      async function fetchEventsByPoll(id){
         const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/events?poll=${id}`)
        data.json().then(response=>{
            console.log("playable events->",response);
            //dispatch(setEvent(response));
            setEvents(response)
        });
    }

    function test(data){
     console.log(data);
    }
    
    async function fetchEventsNoPoll(id){
      const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/events?comp=${id}`)
     data.json().then(response=>{
         console.log("playable events 2->",response);
        // dispatch((setEvent(response)));
         setEvents(response)
     })
 }

    
    /* get all the stakes from this particular  */
   async function fetchUserEventChoice(){    
    const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/stake`,
    {headers:{'Authorization':"Bearer "+token}})
     if(data.status === 401){

     }else{
    await data.json().then((response,failure)=>{
        console.log("userEvent->",response);        
        setUserEventChoice(response);       
        dispatch(choosen(response))
    })
  }
   }

        //check closing time
        function checkClosingTime(event){
          if(event.closing_type === "t"){
          const closingTime = new Date(event.closing_time);
          const dateNow = new Date()
         
           if(dateNow > closingTime){
             return true
           }
           return false
         }else{
          if(event.is_closed){
            return true
          }
           return false
         }
          }
    
   async function increment(choice1,event){    
    //check closing time before sending predict request
   if(!Boolean(currentUserId)){
        setShow(true)
        console.log("not logged in");
         return;
   }else if (checkClosingTime(event)){
    window.alert("prediction time expired");
    return;
    //check user if logged in
   }
   setLoadingSpinner(true);
  
  const request = {
      user:user.id,
      event:event.id,
      choice:choice1.id
  }
  
  const requestJson = JSON.stringify(request);     
    const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/stake`,
                   {method:"POST",mode:"cors",
                    headers:{'Content-Type':"application/json","Authorization":"Bearer "+token},body:requestJson})
                   setLoadingSpinner(false);
     if(data.status === 401){
        
     }else if(data.status === 406){//not acceptable
      data.json().then((res)=>{
        //setEvents(res)
      })
     }else{               
     data.json().then((response,failure)=>{ 
      console.log(response)          
        //setEvents(response)
        fetchUserEventChoice()  
        if(id !== undefined){
          fetchEventsByPoll(id);
        }else{
        fetchEventsNoPoll(compId); 
        }
      }) 
      }        
  }

  return (<div>
    {events?.length > 0?
    events.map((event)=>{
      return <PlayableEventView event={event} increment={increment} loadingSpinner={loadingSpinner}/>
    }):<></>}

   <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Please log in to Play</Modal.Title>
        </Modal.Header>
        <Modal.Body>          
          <Button onClick={()=>navigate("/users/login")}>Log in</Button>
          <Link to={"/users/register"}>Register</Link>
        </Modal.Body>       
      </Modal>
  </div>       
 
    )
}

export default PlayableEventList