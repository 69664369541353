import React from "react";
import { Figure,Badge } from "react-bootstrap";
import { BsFillCaretUpFill } from "react-icons/bs";

const PlayableEventChoice = ({choice,selected,onClick})=>{
    
    const styles = {               
        cursor:"pointer"     
    }
    const spans = {
      cursor:"pointer",
      border:"2px solid blue",
      opacity:"0.5"
    }

    return (<div onClick={onClick} className="choice-card">
    <Figure>
 <Figure.Image
  style={selected?spans:styles}
   width={150}
   height={100}
   alt={choice.choice_text}
   src={`${process.env.REACT_APP_BACKEND_URL}${choice.image_url}`}/>
  <Figure.Caption>
  <div><span className="fw-bold">{choice.choice_text}</span>{' '}{choice.stake_count}{' '}
  {choice.correct_choice && <Badge bg="success">*</Badge>}</div>
   <div title="Points to gain if choice is correct"><BsFillCaretUpFill/> <span>{choice.points_to_gain}</span></div>
   </Figure.Caption>
   </Figure>
   </div>)
}

export default PlayableEventChoice