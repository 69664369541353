import React from "react";
import { Container,Row,Col,Card,Button } from "react-bootstrap";
import { useNavigate,Link } from "react-router-dom";
import {BsFillAwardFill,BsFillCalendarDayFill,BsDice2Fill,BsPeopleFill,BsFillStarFill,BsFillArrowDownCircleFill} from "react-icons/bs";
const LearnMore = ()=>{
 

  const navigate = useNavigate()

  window.addEventListener("beforeinstallprompt", event => {
        event.preventDefault()
                
    window.deferedPrompt = event;
  });
  
  const handleButtonClick = () => {
   const promptEvent = window.deferedPrompt;
   if(!promptEvent){
   // DO SOMETHING
   }
  //Show the add to home screen prompt
  
  promptEvent.prompt()
  
  
  promptEvent.userChoice.then((result) => {
        // Reset the deferred prompt variable, since
        // prompt() can only be called once.
        window.deferedPrompt = null;
      });
  }

 return (<Container>
    <Row>
      <div className="jumbotron mt-5">
      <h1>Welcome To Points Rank</h1>
            <p>How good are you at predicting football matches and events? This simple site gives you the chance to predict the result of games with other <Link to={"/members"}>members</Link>,gain points and ultimately see who will be at the top of the&nbsp;<Link to={"/rankings"}>rankings</Link>.</p>
             <Button onClick={()=>navigate("/")}>Start Now!</Button>{" "}
             {!!window.chrome?<Button onClick={handleButtonClick}><BsFillArrowDownCircleFill size={20}/></Button>:<></>}
          </div>
    </Row>
    <Row className="mt-5">
        <div>
        <h2 className="text-center">What can i do here?</h2>
          <p className="text-center">The content below outlines some of the nice features of the site</p>
        </div>
    </Row>
    <Row className="mt-2">
    <Col md={4} className="mb-2">
          <Card className="info-cards">
           
            <Card.Body>
              <Card.Title className="lead"><BsFillCalendarDayFill/> View Events</Card.Title>
              <Card.Text>
                View upcoming events to predict on and gain points, past events can also
                be viewed aswell.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-2">
          <Card className="info-cards">
            
            <Card.Body>
              <Card.Title className="lead"><BsDice2Fill/> Play</Card.Title>
              <Card.Text>
                Choose between the presented possible outcomes and gain points if your choice
                is equal to the correct outcome
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-2">
          <Card className="info-cards">
            
            <Card.Body>
              <Card.Title className="lead"><BsFillAwardFill/> Ranking</Card.Title>
              <Card.Text>
                The ranking system allows you to see the rankings by competition
                and see the players at the top of the ranks or the losers below
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
    </Row>
    <Row className="mt-2">
    <Col md={6} className="mb-2">
          <Card className="info-cards">            
            <Card.Body>
              <Card.Title className="lead"><BsPeopleFill/> Members</Card.Title>
              <Card.Text>
                View profiles of other members and track their plays and winnings,
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6} className="mb-2">
          <Card className="info-cards">
            
            <Card.Body>
              <Card.Title className="lead"><BsFillStarFill/> Other Features</Card.Title>
              <Card.Text>
                Other fun features are on the works and will be added later.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
    </Row>
 </Container>)
}

export default LearnMore