import React,{useEffect,useState,useReducer} from "react";
import { useSelector } from "react-redux";
import {Container,Row,Col,Spinner,Alert} from "react-bootstrap";

const AdminEventChoices = ()=>{
 
 const initialState = {values:[]};

 const [loadingSpinner,setLoadingSpinner] = useState(false);
 const [show,setShow] = useState(false);

 const token = useSelector((state)=>state.auth.value.token);
 const [state,dispatch] = useReducer(reducer,initialState); 

    useEffect(()=>{
        fetchChoicesEvents();
     },[])

 function reducer(state,action){
    switch (action.type){
        case "setEventChoice":            
            console.log("reducer action->",action.payload);
            if(action.payload.length > 0){
            return {...state,values:[...state.values,...action.payload]}            
            }else{
                return state
            }
        case "onEventChanged": 
          let eventData = [...state.values];
          const booleanVal = action.e.target.value === "on"?true:false;
          eventData[action.index][action.e.target.name] = booleanVal;   
            //console.log("nbbhgv->",eventData);        
            return{
                ...state,
                values:state.values.map((event,i)=>{
                    return i === action.index?{...eventData[action.index]}:event
                })
            }
        case "onChoiceChanged":
            let choiceData = [...state.values[action.index].choices]
            const booleanVal2 = action.e.target.value === "on"?true:false;
            choiceData[action.choiceIndex][action.e.target.name] = booleanVal2;
            return{
                ...state,
                values:state.values.map((event,i)=>{
                    return i === action.index?{...event,choices:event.choices.map((choice,i)=>{
                        return choice
                    })}:event
                })
            }
    }
    
}

async function fetchChoicesEvents(){
    const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/admin-polls/choice_events`,
    {headers:{'Content-Type':"application/json","Authorization":"Bearer "+token}})
    data.json().then((res)=>{
    
      dispatch({type:"setEventChoice",payload:res});    
    });  
}

async function onSubmit(e){
    e.preventDefault();
    setLoadingSpinner(true);
    setShow(false);
    console.log("state values->",state.values);
    const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/admin-polls/choice_events`,{
        method:"POST",
        body:JSON.stringify(state.values),
        headers:{'Content-Type':"application/json","Authorization":"Bearer "+token}
    });
    setLoadingSpinner(false);
    if(data.status === 200){
        setShow(true);
    data.json().then((res)=>{
       // dispatch({type:"setEventChoice",payload:res}); 
        //console.log(res);
     })
    }
}

    return (<Container>
        <Row>
            <h1>AdminEventChoices</h1>       
            
            <Col> 
            <form onSubmit={onSubmit}>
            {state?.values.length > 0 ?
             state.values.map((event,index)=>{
              return (<div style={{border:"1px solid blue",margin:"2px"}}>
                <p>{event.event_question}</p>
                <label for="is_resolved">Is Resolved</label>
                <input name="is_resolved" type={"checkbox"}  
                 onChange={(e)=>dispatch({type:"onEventChanged",e:e,index:index})}
                /> 
                              
                <fieldset>
                    <legend>Choices</legend>
                    <Row>
                {event.choices?.length > 0?
               event.choices.map((choice,choiceIndex)=>{
                return <Col>
                 <p>{choice.choice_text}</p>
                 <label for="correct_choice">Correct Choice</label>
                 <input name="correct_choice" type={"checkbox"} 
                 onChange={(e)=>dispatch({type:"onChoiceChanged",e:e,index:index,choiceIndex:choiceIndex})}/>
                </Col>
               }):<></>                   
            }
            </Row>
            </fieldset>
            
              </div>)
             }):<></>
            }        
            <button type="submit">Submit</button>   
            </form>
            {loadingSpinner?<div className="d-flex justify-content-center"><Spinner animation="border" role="status">
           <span className="visually-hidden">Loading...</span>
             </Spinner></div>:<></>}
             {show?
                <Alert variant="success">
                    events resolved
                  </Alert>:<></>
             }
            </Col>
        </Row>
    </Container>)
}

export default AdminEventChoices