import { useEffect,useState } from "react"


export const useUserPlayHistoryHook = (pageNumber,id)=>{
 const [playerData,setPlayerData] = useState({results:[]})
 const [loadingSpinner,setLoadingSpinner] = useState(true)
 const [error,setError] = useState(false)
 const [hasMore,setHasMore] = useState(false)


async function fetchPlayerHistory(){
    setLoadingSpinner(true)
    setError(false)
    const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/stake-history?user=${id}&page=${pageNumber}`)
    if(data.status === 401){
      
    }else{
    data.json().then((response,reject)=>{ 
        setLoadingSpinner(false)        
        setPlayerData({...playerData,...response,results:[...playerData.results,...response.results]})
        
        //setStakeData({...stakeData,...response,results:[...response.results]})
        setHasMore(response.next !== null)        
        setLoadingSpinner(false)
        
    }).catch((error)=>{
        setError(true)
    }) 
}
}

useEffect(()=>{    
 fetchPlayerHistory()
},[pageNumber])
    return {loadingSpinner,error,playerData,hasMore}
}
