import {createSlice} from "@reduxjs/toolkit";

const initialState = null
const userProfileSlice = createSlice({
    name:"userProfile",
    initialState:{value:initialState},
    reducers:{
        addUserProfile:(state,action)=>{
            state.value = action.payload
        },
        clearUserProfile:(state,action)=>{
            state.value = null
        }      
    }
});

export const {addUserProfile,clearUserProfile} = userProfileSlice.actions
const userProfileReducer = userProfileSlice.reducer
export default userProfileReducer