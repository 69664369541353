import React,{useState,useEffect,useReducer} from "react";
import { Col, Container,Row,Spinner,Alert } from "react-bootstrap";
import { useSelector } from "react-redux";


const AdminEvents = () =>{
    const [polls,setPolls] = useState([]);
    const [competitions,setCompetitions] = useState([]);
    const [seasons,setSeasons] = useState([]);    
    const [choices,setChoices] = useState([]);

    const [loadingSpinner,setLoadingSpinner] = useState(false);
    const [show,setShow] = useState(false);

   

    const token = useSelector((state)=>state.auth.value.token); 


    let currentState = {values:[{
        type:"m",event_question:"",closing_time:null,
        start_time:new Date(),poll:0,competition:"",season:"",
         is_gradeble:true,closing_type:"t",
        slug:"",choices:[{choice:"",points_to_gain:0}]}]};

    function reducer(state,action){
         switch(action.type){
            case "addChoiceField":               
                return {
                    ...state,
                    values:state.values.map((content,i)=>{
                        return i === action.index?{...content,choices:[...content.choices,action.payload]}:content
                    })
                }
            case "removeChoiceField":
                console.log("choice index -> ",action.index," choice index->",action.choiceIndex);
                return {
                    ...state,
                    values:state.values.map((content,i)=>{
                        return i === action.index?{...content,
                            choices:[...content.choices.filter((item,index)=> index !== action.choiceIndex)]}:content
                    })
                }
            case "onChoiceChanged":
                let data = [...state.values[action.eventIndex].choices]
                data[action.index][action.event.target.name] = action.event.target.value;   
                console.log("choice changed",action.event.target.value);  
                return {
                    ...state,
                    values:state.values.map((value,i)=>{
                        return i === action.eventIndex?{
                         ...value,choices:value.choices.map((choice,chi)=>{
                            return choice
                         })
                        }:value;
                    })
                }            
            case "addEventField":
                console.log("object referer->",action.payload);
                return {values:[...state.values,action.payload]};
            case "removeEventField":
                const data2 = [...state.values];
                return{
                 ...state,
                 values:data2.filter((item,index)=>action.index !== index)    
                }

            case "onEventChanged":
                //copy data by spreading
                //go to index object and attribute name, then assign new value
                let data1 = [...state.values];
                data1[action.index][action.event.target.name] = action.event.target.value; 
                console.log(data1);   
                return {
                    ...state,
                    values:state.values.map((item,i)=>{
                        return i === action.index?{...item,...data1}:item
                    })
                }    
                case "addSeasonOnFetch":
                    let data4 = [...state.values]; 
                   if(action.payload.length > 0){
                   data4[data4.length-1].season = action.payload[0].id    
                   return {...state,...state.values,data4}
                   //console.log("val ",state.values);
                   } 
                   return state
                case "addCompetitionOnFetch":
                    let data5 = [...state.values]; 
                   if(action.payload.length > 0){
                   data5[data5.length-1].competition = action.payload[0].id    
                   return {...state,...state.values,data5}
                   //console.log("val ",state.values);
                   } 
                   return state

                       
        }      
}

    const [state,dispatch] = useReducer(reducer,currentState);
    
    /*  const [eventFields,setEventFields] = useState([{
        type:"",event_question:"",closing_time:"",
        start_time:"",poll:"",competition:"",season:"",
         is_resolved:false,is_gradeble:true,closing_type:"m",is_closed:false,
        slug:"",is_postponed:false,choices:[{choice:"",points_to_gain:0,event_index:0}]}])  */

          useEffect(()=>{
          fetchCompetitions();
           fetchPolls();
           fetchSeasons();           
           fetchChoices();
          },[]) 


           useEffect(()=>{
            let data = [...state.values]
            if(data.length > 0 && seasons.length > 0){
             // data[data.length-1].season = polls[polls.length-1].id '
             dispatch({type:"addSeasonOnFetch",payload:seasons});             
            }
          },[seasons])

          useEffect(()=>{
            let data = [...state.values]
            if(data.length > 0 && competitions.length > 0){
             // data[data.length-1].competition = polls[polls.length-1].id '
             dispatch({type:"addCompetitionOnFetch",payload:competitions});             
            }
          },[competitions]) 

    async function fetchCompetitions(){
        const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/competitions`)
        data.json().then((res)=>{
           setCompetitions(res);        
        });  
    }
     
       async function fetchSeasons(){
        const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/seasons`);
        data.json().then((res)=>{
           setSeasons(res)
          
        })  
       }

       async function fetchPolls(){
        const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/admin-polls`)
        data.json().then((res)=>{
           setPolls(res);        
        })  
       }

       async function fetchChoices(){
        const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/admin-polls/choices`,
        {headers:{'Content-Type':"application/json","Authorization":"Bearer "+token}
        });
        data.json().then((res)=>{            
         setChoices(res);                
        })  
       }

       function addChoiceFields(index){
       // if(eventFields !== undefined){
        //console.log("choices ->",eventFields[index].choices);
        
         const object = {choice:"",points_to_gain:""};       
         if(choices.length > 0){
            object.choice = choices[0].id;
         }  
        /*  let data = [...eventFields[index].choices,object];
         
         let eventChoices = {...eventFields[index],choices:data};
         console.log("data ->",data,"index ->",index); */
        
        /*  setEventFields(prevState =>{
            prevState[index].choices = data            
            return prevState
         }); */

         dispatch({type:"addChoiceField",payload:object,index:index});
      // }
        
       }
       function removeChoiceField(index,choiceIndex){
        dispatch({type:"removeChoiceField",index:index,choiceIndex:choiceIndex});
        //console.log("index ->",index,"choiceIndex ->",choiceIndex);
       }

       function addFields(){
        const object = {
       type:"",event_question:"",closing_time:"",
       start_time:"",poll:0,competition:"",season:"",
        is_gradeble:true,closing_type:"m",
        slug:"",choices:[{choice:"",points_to_gain:0}]};
           
        if(competitions.length > 0 && seasons.length > 0){             
        object.competition = competitions[0].id;
        object.season = seasons[0].id;        
       } 
 
       //setEventFields([...eventFields,object]);
       dispatch({type:"addEventField",payload:object})
       }

        function removeFields(index){
       // const data = [...eventFields];
         //data.splice(index,1);
         //setEventFields(data);
        //console.log("index->",index); 
        dispatch({type:"removeEventField",index:index});
       } 

       function handleFormChange(event,index){
       // let data = [...state.values];
       // data[index][event.target.name] = event.target.value;
        // setEventFields(data);
       //  console.log(event.target.name,"->",index,"->",event.target.value);
       // console.log("form change");
        dispatch({type:"onEventChanged",event:event,index:index});
       }

       function handleChoiceChange(event,eventIndex,index){
           //let data = [...state.values[eventIndex].choices]
           //data[index][event.target.name] = event.target.value;
           console.log("event->",event.target.value);
           dispatch({type:"onChoiceChanged",eventIndex:eventIndex,index:index,event:event});
       }

       async function submit(e){
         e.preventDefault();
         setLoadingSpinner(true);
         setShow(false);
         console.log("eventfields ->",state.values);
           const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/admin-polls/events`,{
            method:"POST",
            body:JSON.stringify(state.values),
            headers:{'Content-Type':"application/json","Authorization":"Bearer "+token}
        });
        setLoadingSpinner(false);
        if(data.status === 200){
            setShow(true);
        data.json().then((res)=>{
            console.log(res)
         })  
        } 
        }

    return (
        <Container>
            <Row>             
                <h1>Add Events</h1>
                <Col>
                <form onSubmit={submit}>
                    {state !== undefined?
                    state.values.map((eventField,index)=>{
                    return (<div><div><label for="type">type</label>
                    <select onChange={e => handleFormChange(e,index)} name="type">
                        <option value="m">Match</option>
                        <option value="q">Question</option>
                    </select>
                 </div>

                    <div><label for="event_question">Event Question</label>
                    <input type="text" maxLength={300} name="event_question" onChange={e => handleFormChange(e,index)}
                     value={eventField.event_question}/>
                    </div>

                    <div><label for="closing_time">Closing Time</label>
                    <input type="datetime-local" name="closing_time" onChange={e => handleFormChange(e,index)}
                     value={eventField.closing_time}/>
                    </div>

                    <div><label for="start_time">Start Time</label>
                    <input type="datetime-local" name="start_time" onChange={e => handleFormChange(e,index)}
                    value={eventField.start_time}/>
                    </div>

                    <div><label for="competition">Competition</label>
                  <select name="competition" onChange={e => handleFormChange(e,index)}>
                   {competitions.length > 0?
                     competitions.map(item =>{      
                       return (<option value={item.id}>{item.name}</option>)
                     })   
                    :<></>}
             </select></div>

                <div><label for="season">Season</label>
               <select name="season" onChange={e => handleFormChange(e,index)}>
                 {seasons.length > 0?
                seasons.map(item =>{      
                return (<option value={item.id}>{item.title}</option>)
                })   
                :<></>}
                </select>
                </div>

                <div><label for="poll">Poll</label>
            <select name="poll" onChange={e => handleFormChange(e,index)}>
            <option value={0}>-</option>
             {polls.length > 0?
                polls.map(item =>{      
                return (<option value={item.id}>{item.relevance_date}</option>)
             })   
             :<></>}
             </select>
            </div>

            <div><label for="closing_type">closing type</label>
                 <select onChange={e => handleFormChange(e,index)} name="closing_type">
                   <option value="t">Time</option>
                     <option value="m">Manual</option>
                    </select>
                    </div>                 

                    <div><label for="is_gradeble">is Gradeble</label>
                    <select onChange={e => handleFormChange(e,index)} name="is_gradeble">
                        <option value={true}>True</option>
                        <option value={false}>False</option>
                    </select>
                    </div>                    
                    <Row>                    
                        {eventField.choices.map((choice,choiceIndex)=>{                       
                        return(<Col>
                     <fieldset>
                        <legend>Choices</legend>                    
                        <div>
                        <label for="choice">Choice</label>
                        <select  onChange={e=>handleChoiceChange(e,index,choiceIndex)} name="choice">
                            {choices.length > 0?
                        choices.map((choice,i)=>{
                            return <option value={choice.id}>{choice.choice_text}</option>
                        }):<></>    
                        }
                        </select>                   
                        </div>
                        <div>
                        <label for="points_to_gain">probablity(%)</label>
                    <input type="text" name="points_to_gain" onChange={e=>handleChoiceChange(e,index,choiceIndex)} value={choice.points_to_gain} />
                        </div> 
                        <button onClick={()=>removeChoiceField(index,choiceIndex)}>Remove Choice</button>   
                        </fieldset>                
                    </Col>) })}                    
                    </Row>
                    <button onClick={()=>removeFields(index)}>Remove</button>
                    <button onClick={()=>addChoiceFields(index)}>Add Choice</button>
                    </div>)
                }):<></>}     
                    <button onClick={()=>addFields()}>Add Event</button>       
                    <button type="submit">Submit</button>                    
                </form>
                {loadingSpinner?<div className="d-flex justify-content-center"><Spinner animation="border" role="status">
           <span className="visually-hidden">Loading...</span>
             </Spinner></div>:<></>}
             {show?
                <Alert variant="success">
                    event created
                  </Alert>:<></>
             }
                </Col>
            </Row>
        </Container>
    )

}

export default AdminEvents