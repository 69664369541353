import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import authReducer from './redux/reducers/authSlice';
import userProfileReducer from './redux/reducers/userProfileSlice';
import  {Provider } from "react-redux";
import {configureStore} from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {PersistGate} from "redux-persist/integration/react"
import persistStore from 'redux-persist/es/persistStore';
import persistReducer from "redux-persist/es/persistReducer"
import thunk from 'redux-thunk';
import { combineReducers } from '@reduxjs/toolkit';
import userChoiceReducer from './redux/reducers/userChoiceSlice';
import {GoogleOAuthProvider} from "@react-oauth/google";
import { client_id } from './components/google-auth-components/gclient';

if(process.env.REACT_APP_DEBUG === 'yes'){

}else{
let noOp = function(){}; // no-op function
console = {
  log: noOp,
  warn: noOp,
  error: noOp
}
}

const persistConfig = {
  key:"rankkings",
  storage
}

const reducers = combineReducers(
  {"auth":authReducer,
"userProfile":userProfileReducer,
"userChoice":userChoiceReducer}
)

const persistedReducer = persistReducer(persistConfig,reducers)

const store = configureStore({
  reducer:persistedReducer,
  middleware:[thunk],
  devTools:false
})

const persistedStore = persistStore(store)

ReactDOM.render(

  <React.StrictMode>
    <GoogleOAuthProvider clientId={client_id}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistedStore}>        
    <App />
    </PersistGate>
    </Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
