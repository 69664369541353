import React,{useState,useEffect} from "react"
import { useSelector } from "react-redux";
import { Container,Row,Col,Card,Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const StakeHistoryView = ({stake})=>{
     const navigate = useNavigate()
      
        return(<Card className="m-2 p-2 pointer" onClick={()=>navigate(`/event/${stake.event.id}/${stake.event.slug}`,{state:{id:stake.event.id}})}>
          <div><img width={32} height={32} src={`${process.env.REACT_APP_BACKEND_URL}${stake.competition_logo}`}/>
          <b>{stake.event_title}</b>
          <i className="ms-2">{stake.relevance_date}</i>
          </div>
          {stake.is_graded?
          <div>Chose (<b>{stake.choice_name}</b>) | {stake.is_win?<Badge bg="success">won</Badge>:<Badge bg="danger">lost</Badge>}</div>:<i>Unresolved</i>}          
          <div>{new Date(stake.staked_at).toLocaleString()}</div>
        </Card>)     
    
  }      
  
 
  export default StakeHistoryView