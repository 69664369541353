import React,{useEffect,useState} from "react"
import {Container,Row,Col,Spinner,ListGroup,Dropdown,Accordion,Figure, DropdownButton} from "react-bootstrap"
import { useDispatch,useSelector } from "react-redux"
import { addUserProfile } from "../../redux/reducers/userProfileSlice"
import {logout} from "../../redux/reducers/authSlice"

import {Outlet,useNavigate} from "react-router-dom";

import "./Homepage.css";

const Homepage = () => {      
    
    const [polls,setPolls] = useState([])
    const [userProfileData,setUserProfileData] = useState({})
    const [competitions, setCompetitions] = useState([])
    const [loadingSpinner,setLoadingSpinner] = useState(false)

    const navigate = useNavigate()

    const dispatch = useDispatch();
    const data = useSelector((state)=>state)
    const token = useSelector(state=>state.auth.value.token)    
    const userid = localStorage.getItem("uid")

    async function fetchCompetitions(){
        setLoadingSpinner(true)
        const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/competitions`)
        data.json().then(response=>{
            setLoadingSpinner(false)
            setCompetitions(response)
            console.log("competitions",response);            
        })
    }
         
    async function fetchPolls(){
        const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events`)
        data.json().then(response=>{
            setPolls(response)
            console.log(response)            
        })
    }

    const fetchUserProfileData = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/userprofile/`,{
          headers:{"content-Type":"application/json","Authorization":"Bearer "+token},method:"GET",mode:"cors"})
          if(response.status === 401){
           dispatch(logout())
          }else{
         response.json().then(res=>{
         setUserProfileData(res)
         dispatch(addUserProfile(res))
         console.log("user",res)
          })
        }
        }

    function changeLocation(id,slug){
      navigate(`competitions/${id}/${slug}`,{replace:true});
      window.location.reload();
    }

    function allClicked(){
        navigate("/",{replace:true});     
    }

   useEffect(()=>{     
       fetchPolls();                 
   },[polls.length])

   useEffect( ()=>{     
     fetchUserProfileData();
     fetchCompetitions(); 
   },[])

    return (    
    <Container>        
        <Row className="mt-5">  
         <Col md={3} className="mb-3">    

    <Accordion defaultActiveKey="0" className="competition-accordion">
      <Accordion.Item eventKey="0">
        <Accordion.Header><span className="font-arial">Competitions</span></Accordion.Header>
        <Accordion.Body>
        <ListGroup variant="flush">
      <ListGroup.Item action onClick={()=>allClicked()}>All</ListGroup.Item>
      {loadingSpinner?<div className="d-flex justify-content-center p-2"><Spinner animation="border" role="status">
           <span className="visually-hidden">Loading...</span>
             </Spinner></div>:
      competitions.length > 0?
       competitions.map((comp)=>{
      return(        
        <ListGroup.Item key={comp.id} action onClick={()=>changeLocation(comp.id,comp.slug)}>{comp.name}</ListGroup.Item>     
      )}):        
        <ListGroup.Item action>No Competitions</ListGroup.Item>      
      }
      </ListGroup>
        </Accordion.Body>
      </Accordion.Item>      
    </Accordion>
         </Col>          
        <Col md={7}> 

   {/* dropdown competitions for mobile */}   
   <div className="mobi-competitions">
   <div className="mobi-competition-item" onClick={()=>allClicked()}><span>All</span></div>
        {competitions.length > 0?
        competitions.map(comp=>{
          return  <div className="mobi-competition-item" key={comp.id} onClick={()=>changeLocation(comp.id,comp.slug)}><span>{comp.name}</span></div>
        }):<></>

         }
         </div>     
 
       <Outlet/>           
       </Col>
       <Col md={2}>
       {/* <h1 className="lead">Ads</h1>        */}
       </Col>
       </Row>
    </Container>    
   )
}

export default Homepage