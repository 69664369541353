import React,{useState} from "react";
import { useDispatch,useSelector } from "react-redux"
import {Container,Button,Row,Alert,Form,Spinner} from "react-bootstrap"
import { Link,useNavigate,useLocation} from "react-router-dom";
import { login } from "../../redux/reducers/authSlice";
import { clearUserProfile,addUserProfile} from "../../redux/reducers/userProfileSlice";
import { GoogleLogin } from "@react-oauth/google";

const Login = ()=>{
    const dispatch = useDispatch()
    //const user = useSelector((state)=>state.auth.value.user)
    const [loadingSpinner,setLoadingSpinner] = useState(false)
    const [responseErrors,setResponseErrors] = useState([])
    const [passwordShown, setPasswordShown] = useState(false);
    let [show, setShow] = useState(true);

    const navigate = useNavigate()
    const location = useLocation()


    
    

    async function submit(e){
        e.preventDefault()
        setLoadingSpinner(true)
        setShow(false)
        setResponseErrors([])
         const request_data = {
           email:e.target[0].value,
           password:e.target[1].value
         }
          console.log(e.target[0].value);
      
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/login`,{method:"POST",mode:"cors", body: JSON.stringify(request_data),
            "headers":{"Content-Type":"application/json"}})
            setLoadingSpinner(false)
            if(response.status != 200){
             response.json().then((res)=>{
              setResponseErrors(res.non_field_errors)              
             })
            }else{
           await response.json().then((data,error)=>{
            
             console.log("login->",data);
              dispatch(login(data))  
              fetchUserProfileData(data?.token)
              //go to home page after login             
                   
           })      
          }
       }

       const fetchUserProfileData = async (res_token) => {
        
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/userprofile/`,{
          headers:{"content-Type":"application/json","Authorization":"Bearer "+res_token},method:"GET",mode:"cors"})
          if(response.status === 401){
           //dispatch(logout())
          }else{
         response.json().then(res=>{   
         dispatch(addUserProfile(res))
         navigate("/");
          })
        }
        }

        async function googleLoginSuccess(res){
          setLoadingSpinner(true);
          console.log("google login->",res);
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/google-login`,{method:"POST",mode:"cors", body: JSON.stringify(res?.credential),
                   "headers":{"Content-Type":"application/json"}}) 
                   setLoadingSpinner(false);  
           if(response.status === 200){
            response.json().then((data)=>{
              dispatch(login(data))

              //dispatch(addUserProfile(data.user))
              fetchUserProfileData(data?.token);
              //navigate("/");
              console.log("login->",data);
            })   
           }        
         
        }
        
function googleLoginError(){
  console.log("login failed");
}
  
    return ( <Container> 
        <Row>
          {/* <div className="d-flex justify-content-center mt-5">
          <img src="holder.js/100px160" alt="logo image"/>
          </div> */}
        <h1 className="text-center fw-bold mt-5">Login</h1>
        {loadingSpinner?<div className="d-flex justify-content-center"><Spinner animation="border" role="status">
           <span className="visually-hidden">Loading...</span>
             </Spinner></div>:<></>}            
           <Form onSubmit={(e)=>{submit(e)}}>
            {responseErrors != undefined &&  responseErrors.map(error=>{
              return  <Alert  variant={"danger"}>
              {error}
            </Alert>
            })}


            {show && location.state !== null ? <Alert variant="success" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>Confirm Email</Alert.Heading>
        <div>
          Almost done,a confirmation link has been sent to your email, click the link in your email to complete
          the registration.
        </div>
      </Alert>:<></>}   
      <div className="d-flex justify-content-center m-3"><GoogleLogin                  
                  onSuccess={googleLoginSuccess}
                  onError={googleLoginError}
                  /> </div>
<Form.Group className="mb-3" controlId="formBasicEmail">
<Form.Label>Email</Form.Label>
<Form.Control type="email" required placeholder="Enter username" />
</Form.Group>

<Form.Group className="mb-3" controlId="formBasicPassword">
<Form.Label>Password</Form.Label>
<Form.Control required type={passwordShown?"text":"password"} placeholder="Password" />
</Form.Group> 
<Form.Group className="mb-3" controlId="formBasicCheckbox">
 <Form.Check type="checkbox" label="Show Password" onChange={(e)=>setPasswordShown(e.target.checked)} />
  </Form.Group> 
<div className="mw-100 d-flex">
<div>
<Button variant="primary" type="submit">
Login
</Button></div>
<div className="ps-2"><Link className="text-decoration-none" to={"/users/register"}>Register</Link></div><div className="ps-2"><Link className="text-decoration-none" to={"/users/forgot-password"}>Forgot password?</Link></div></div>
</Form>
</Row>
</Container> )
}

export default Login