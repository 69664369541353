import {Outlet,Navigate, redirect} from 'react-router-dom';

import Header from '../header/Header';

import Footer from '../footer/Footer';
import { useSelector,useDispatch } from 'react-redux';
import {login} from "../../redux/reducers/authSlice"
import { useState,useEffect } from 'react';




const MainPage =  ()=>{
  const [redirect,setRedirect] = useState(false)
  const auth = useSelector((state)=>state.auth.value)
  //const navigate = useNavigate()
  const dispatch = useDispatch()
  
  async function refreshToken(){
    setRedirect(false)
      const request = {
      token:auth.token
    }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/api-token-refresh`,{
      method:"POST",             
      mode:"cors",
      headers:{"Content-Type":"application/json"},
      body: JSON.stringify(request)
   }) 
   if(response.status === 400){
    setRedirect(true)
   }else if(response.status === 500){
  
   }else{
    response.json().then((res)=>{
      dispatch(login(res))
    })     
   }
  }
  
  useEffect(()=>{
    if(auth.isLoggedIn){
    refreshToken()
    }
  },[])
  
  

  return(<>  
  <Header/>
  {redirect?<Navigate to={"/users/login"}/>:<Outlet/>}
  <Footer/>
  </>)
}

export default MainPage