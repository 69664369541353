import React,{useEffect,useState} from "react"
import { Badge, Figure } from "react-bootstrap"
import {BsFillCaretUpFill} from "react-icons/bs"
import "./event_choice.css"

const EventChoice = ({choice,onClick,imageUrl,selected})=>{
 
    const styles = {               
        cursor:"pointer"     
    }
    const spans = {
      cursor:"pointer",
      border:"2px solid blue",
      opacity:"0.5"
    }

    return (<div onClick={onClick} className="w-100 choice-card">
      <Figure>
      <Figure.Image
        style={selected?spans:styles}
        width={200}
        height={200}
        alt={choice.choice.choice_text}
        src={`${process.env.REACT_APP_BACKEND_URL}${imageUrl}`}
      />
      <Figure.Caption >
       <div>{choice.choice.choice_text}{' '}{choice.stake_count}{' '}
        {choice.correct_choice && <Badge bg="success">-</Badge>}</div>
        <div><BsFillCaretUpFill/> <span>{choice.points_to_gain}</span></div>
      </Figure.Caption>
    </Figure>
    </div>)
}

export default EventChoice