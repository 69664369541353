import React, { useState } from "react";
import { useDispatch,useSelector } from "react-redux"
import {Container,Button,Form,Row,Col,Spinner,Alert} from "react-bootstrap"
import {Link,useNavigate} from "react-router-dom";
import { login } from "../../redux/reducers/authSlice";
import { clearUserProfile,addUserProfile} from "../../redux/reducers/userProfileSlice";
import { GoogleLogin } from "@react-oauth/google";

const Register = ()=>{
    const dispatch = useDispatch()
    const user = useSelector((state)=>state.auth.value.user)
    const [responseErrors,showResponseErrors] = useState([])
    const [loadingSpinner,setLoadingSpinner] = useState(false)
    const [passwordShown, setPasswordShown] = useState(false);
    const [fieldError,setFieldErrors] = useState([{
      username:[""],
      email:[""],
      password:[""],
    }]) 
    const [emailError,showEmailError] = useState(false)

    const navigate = useNavigate()
    
    async function userRegisterPostAction(requestJson){
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/register`,{method:"POST",mode:"cors",body:JSON.stringify(requestJson),
        headers:{"Content-Type":"application/json"}})
        setLoadingSpinner(false)
        if(response.status === 500){
          showEmailError(true)   
        }else if(response.status === 400){
          response.json().then((response,failure)=>{
            setFieldErrors(response)   
            console.log(response)       
          })
        }else{                 
          navigate("/users/login",{state:{email:true}});        
      }
      }

      function validateFields(password,confirm_password){
        if(password !== confirm_password){
          setFieldErrors({password:["Passwords dont match"]});
           setLoadingSpinner(false); 
           return false
          }else if(!/^(?=.*[0-9])(?=.*)[a-zA-Z0-9*]{6,16}$/.test(password)){
          setFieldErrors({password:["Password is too weak"]});
          setLoadingSpinner(false)
          return false;
        }else{
          return true
        }
      }
      function onSubmit(e){
        e.preventDefault()
        setLoadingSpinner(true)
        setFieldErrors({username:[""],email:[""],password:[""],})
        showResponseErrors(false)
        showEmailError(false)
        const password = e.target[2].value
        const confirm_password = e.target[3].value
        if(!validateFields(password,confirm_password)){
          return;
        }
        const formObj = {
          username:e.target[0].value,
          email:e.target[1].value,
          password:e.target[2].value,
        }        
        //console.log(formObj);    
        userRegisterPostAction(formObj);
       }

       async function googleLoginSuccess(res){
         setLoadingSpinner(true);
        console.log("google login->",res);
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/google-login`,{method:"POST",mode:"cors", body: JSON.stringify(res?.credential),
                 "headers":{"Content-Type":"application/json"}})  
                 setLoadingSpinner(false); 
         if(response.status === 200){
          response.json().then((data)=>{
            dispatch(login(data))

            //dispatch(addUserProfile(data.user))
            fetchUserProfileData(data?.token);            
            console.log("login->",data);
          })   
         }        
       
      }
      
function googleLoginError(){
console.log("login failed");
}

const fetchUserProfileData = async (res_token) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/userprofile/`,{
    headers:{"content-Type":"application/json","Authorization":"Bearer "+res_token},method:"GET",mode:"cors"})
    if(response.status === 401){
     //dispatch(logout())
    }else{
   response.json().then(res=>{   
   dispatch(addUserProfile(res))
   navigate("/");
   
    })
  }
  }


    return ( <Container> 
          <Row>
            <Col md={6}>
            <h1 className="text-center fw-bold">Register</h1>
            
            {emailError?<Alert variant="danger" onClose={() => showEmailError(false)} dismissible>
        <Alert.Heading>Error!</Alert.Heading>
        <p>
          Something went wrong, try again
        </p>
      </Alert>:<></>} 
            {responseErrors != undefined && responseErrors.length > 0?
              responseErrors.map(error=>{
              return <Form.Text className="bg-danger">{error}</Form.Text>
            }):<></>
            }
 <div className="d-flex justify-content-center m-3"><GoogleLogin                  
                  onSuccess={googleLoginSuccess}
                  onError={googleLoginError}
                  /> </div>

               {/* form */}
               <Form onSubmit={onSubmit}>
  <Form.Group className="mb-1" controlId="formBasicText">
    <Form.Label>Username</Form.Label>
    <Form.Control required type="text" maxLength={25} placeholder="Enter username" />
    <Form.Text  style={{color:"red"}}>{fieldError?.username}</Form.Text>    
  </Form.Group>

  <Form.Group className="mb-1" controlId="formBasicEmail">
    <Form.Label>Email</Form.Label>
    <Form.Control required type="email" placeholder="Enter email" /> 
    <Form.Text  style={{color:"red"}}>{fieldError?.email}</Form.Text>    
  </Form.Group>

  <Form.Group className="mb-1" controlId="formBasicPassword">
    <Form.Label>Password</Form.Label>
    <Form.Control required type={passwordShown?"text":"password"} placeholder="Password" />
    <Form.Text  style={{color:"red"}}>{fieldError?.password}</Form.Text> 
    <div><Form.Text>Must include</Form.Text>
    <ul>      
      <li><Form.Text>a number e.g 2</Form.Text></li>
      <li><Form.Text>length must be greater than 5</Form.Text></li>
    </ul></div>
  </Form.Group> 

  <Form.Group className="mb-2" controlId="formBasicPassword">
    <Form.Label>Confirm Password</Form.Label>
    <Form.Control required type={passwordShown?"text":"password"} placeholder="Password" />
  </Form.Group>
  <Form.Group className="mb-3" controlId="formBasicCheckbox">
 <Form.Check type="checkbox" label="Show Password" onChange={(e)=>setPasswordShown(e.target.checked)} />
  </Form.Group> 
  <Button variant="primary" type="submit">
  {loadingSpinner?<Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        >
        <span className="visually-hidden">Loading...</span></Spinner>:"Register"}
  </Button>
</Form> 
           {/*  form ends */}
           </Col>
           <Col>
           
           </Col>
           </Row>
      </Container> )
}

export default Register