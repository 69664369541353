import React,{useLayoutEffect,useEffect} from "react";
import { useLocation } from "react-router-dom";


const ScrollToTop = ()=>{
   const {pathname} = useLocation();


   window.scrollTo(0,0);
    useLayoutEffect(()=>{
        window.scrollTo(0,0);
    },[pathname])

    useEffect(()=>{
        window.scrollTo(0,0);
    },[pathname])

    return null;
}

export default ScrollToTop