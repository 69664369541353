import React,{useEffect,useState,useRef,useCallback} from "react";
import Staker from "./Staker";
import { useParams,useNavigate } from "react-router-dom";
import { Container,Row,Col,Button,Spinner} from "react-bootstrap";
import {BsFillArrowLeftCircleFill} from "react-icons/bs"
import { usePlayersHook } from "./usePlayersHook";

const StakersList = ()=>{  
  const [pageNumber,setPageNumber] = useState(1);
  const {id} = useParams("id")
  
  const navigate = useNavigate()
  const pageEnd = useRef()

  const {loadingSpinner,error,playerData,hasMore} = usePlayersHook(pageNumber,id)

  const observer = useRef()

  const lastElement = useCallback((element)=>{
  console.log(element)
   if(loadingSpinner){ return}
   if(observer.current){observer.current.disconnect()}
    observer.current = new IntersectionObserver(entries=>{
     entries.forEach(entry=>{      
       if(entry.isIntersecting && hasMore){
         setPageNumber(prevPage=>prevPage+1)         
       }
     })
   
    })
    if(element){observer.current.observe(element)}
  },[loadingSpinner,hasMore])
  

    function getEventTitle(){
    if(playerData.results.length > 0){
       return playerData.results[0].event_title
    }
    }

    return (<Container>
          <Button size="sm" className="mt-5 rounded-pill" onClick={()=>navigate(-1)}>
        <BsFillArrowLeftCircleFill size={15}/> Back</Button>
        <Row className="mt-3">
            <Col md={8} ><div className="border-bottom">        
        <h3>Players <span className="fw-bold">{getEventTitle()}</span></h3>
        </div>
           {playerData.results.length > 0?
           playerData.results.map((staker,index)=>{
             if(index+1 === playerData.results.length){
               return (<div ref={lastElement} ><Staker key={staker.id} staker={staker}/></div>)
             }else{
              return (<div><Staker key={staker.id} staker={staker}/></div>)
             }
           }):<></>}
            {loadingSpinner?<div className="d-flex justify-content-center"><Spinner animation="border" role="status">
           <span className="visually-hidden">Loading...</span>
             </Spinner></div>:<></>}
           </Col>
           <Col md={4}>         
           </Col>
           </Row>
        </Container>)
}

export default StakersList