import React,{useEffect,useState,useRef} from "react"
import {Row,Col,Container,Button,Spinner,Badge,Card,Modal} from "react-bootstrap"
import { useNavigate,useParams,Link} from "react-router-dom"
import EventChoice from "./EventChoice"
import { getTimer } from "../utils/countDownTimer"
import { useSelector } from "react-redux"
import { CommentSection} from 'react-comments-section'
import {BsFillArrowLeftCircleFill,BsFillPersonFill,BsFillClockFill} from "react-icons/bs"
import { formatClosingTime } from "../utils/timeFormatUtils"
import 'react-comments-section/dist/index.css'



const EventDetail = ()=>{    
     const user = useSelector((state)=>state.auth.value.user) 
     const userProfile = useSelector((state)=>state.userProfile.value)
     const token = useSelector(state=>state.auth.value.token)
    
     const navigate = useNavigate()
     const params = useParams()

            
    //modal stuff
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
     
     const currentUserId = user.id    

     const styles = {        
      padding:5,
      marginTop:30,       
          }

      const [event,setEvent] = useState({})
      const [userEventChoice,setUserEvent] = useState([])  
      const [comments,setComments] = useState({results:[]})  
      const [userStaked,setUserStaked] = useState(false)
      const [pageNumber,setPageNumber] = useState(1)

      const [loadingExtraComments,setLoadingExtraComments] = useState(false)
      const [loadingEvent,setLoadingEvent] = useState(false)
      const [loadingSpinnerOnChoose,setLoadingSpinnerOnChoose] = useState(false)

      const [rendered,setRendered] = useState(false);
       
      const pageEnd = useRef()

  async function getEvent(){
    setLoadingEvent(true)    
     const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/event/${params.id}`,
     {headers:{'Authorization':"Bearer "+token}})
      if(data.status === 404){
        navigate("/not-found")
      }
     await data.json().then((response,failure)=>{
         console.log(response)
         //setEvent(response)
         setEvent(response)    
         setLoadingEvent(false)
     })
     
      }


 
   /* check predict for this user and the event to see whether the user has a row
   with this event  */
   async function fetchUserEventChoice(){
    const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/stake?event=${params.id}`,
    {headers:{'Authorization':"Bearer "+token}})
     if(data.status === 401){

     }else{
    await data.json().then((response,failure)=>{
        console.log(response)        
        setUserEvent(response)       
    })
  }
   }

   useEffect(()=>{      
       if(pageNumber > 1){         
        getCommentsAction(pageNumber)}       
   },[pageNumber])  

   useEffect(()=>{        
    getEvent()    
    getCommentsAction(pageNumber)  
    fetchUserEventChoice()
   },[]);

   useEffect(()=>{
    window.scrollTo(0,0);
   },[])

/*   useEffect(()=>{
   
     const observer = new IntersectionObserver(entries=>{    
        console.log(pageEnd);
        entries.forEach(entry=>{
          if(entry.isIntersecting){
              loadMore()            
          }
      })         
}) 
     observer.observe(pageEnd.current.lastChild) 
    },[rendered]) */


 
  
/*     function onsubmit(e){
    e.preventDefault()
     
    let count = 4;
    const obj = {
        parent_comment:null,
        content:e.target[0].value,
        event:params.id,
        user:localStorage.getItem("uid")       
    }
    const requestJson = JSON.stringify(obj)
    //postCommentAction(requestJson);
    //setList([...list,obj])
    console.log(obj)
} */

 function checkClosingTime(event){
 if(event.closing_type === "t"){
 const closingTime = new Date(event.closing_time);
 const dateNow = new Date()

  if(dateNow > closingTime){
    return true
  }
  return false
}else{
  if(event.is_closed){
    return true
  }
   return false
 }
}
 
   
 async function increment(choice1){ 
     
       if(!Boolean(currentUserId)){
             setShow(true)
             return;
       }else if (checkClosingTime(event)){ //check closing status
        window.alert("prediction time expired");
        return;
       }
       
setLoadingSpinnerOnChoose(true)

      const request = {
          user:user.id,
          event:event.id,
          choice:choice1.id
      }
      const requestJson = JSON.stringify(request);
      //console.log(requestJson)
        const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/stake`,
                       {method:"POST",mode:"cors",
                        headers:{'Content-Type':"application/json","Authorization":"Bearer "+token},body:requestJson})
                        setLoadingSpinnerOnChoose(false)
         if(data.status === 401){

         }else if(data.status === 406){//not acceptable
          data.json().then((res)=>{
            setEvent(res)
          })
         }else{               
         data.json().then((response,failure)=>{ 
          console.log(response)          
            setEvent(response)
            fetchUserEventChoice()
          }) 
          }
        
                               
      //setEvent({...event})      
   }

   async function  getCommentsAction(pageNumber){ 
   setLoadingExtraComments(true)   
   const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/event/${params.id}/comments?page=${pageNumber}`,{"headers":{"content-Type":"application/json"},method:"GET"})
   response.json().then((response,failure)=>{
    console.log(response)
    setComments({...comments,...response,results:[...comments.results,...response.results]})
          setLoadingExtraComments(false)
          setRendered(true)                       
     })
   }

   function loadMore(){
    if(comments.next !== null){
    setPageNumber(pageNumber=>pageNumber+1)
    } 
     }

   async function postCommentAction(data){
       
    const requestData = {
      userId:data.userId,      
      text:data.text,
      eventId:params.id
    }

     const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/event/${params.id}/comments?pageNumber=${pageNumber}&per_page=10`,
    {"headers":{"content-Type":"application/json"},method:"POST",mode:"cors",body:JSON.stringify(requestData)})
    if(response.status === 401){

    }else{
    response.json().then((response,failure)=>{
       //setComments(response)
    });
  } 
   } 
    
   //this is used for the blue border on a choice if selected before
   function checkSelectedChoice(choice){
         if(userEventChoice.length > 0){
          /* if the choice in userEventChoice is same as one of the choices 
          in the event then return true if no match return false */          
          if(userEventChoice[0].choice.id == choice.id){
            return true
          }else{
            return false
          }
         }else{
          return false
         }  
   }

   async function replyCommentAction(data){
     console.log(data)
     const requestData = {
      userId:data.userId,
      repliedToCommentId:parseInt(data.repliedToCommentId),
      parentOfRepliedToCommentId:data.parentOfRepliedToCommentId,
      text:data.text,
      eventId:params.id,
     }

     const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/event/${params.id}/comments?pageNumber=${pageNumber}&per_page=10`,
     {"headers":{"content-Type":"application/json"},method:"POST",mode:"cors",body:JSON.stringify(requestData)})
     if(response.status === 401){

     }else{
     response.json().then((response,failure)=>{
         //console.log(response);
     }); 
    }
   }

   async function updateCommentAction(data){
    console.log(data)
    const requestData = {           
      text:data.text
    }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/event/${params.id}/comments?key=${data.comId}&pageNumber=${pageNumber}&per_page=10`,
    {"headers":{"content-Type":"application/json"},method:"PUT",mode:"cors",body:JSON.stringify(requestData)})
    if(response.status === 401){

    }else{
    response.json().then((response,failure)=>{
       // setComments(response);        
    }); 
  }
   }
  
   async function deleteCommentAction(data){  
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/event/${params.id}/comments?key=${data.comIdToDelete}&pageNumber=${pageNumber}&per_page=10`,
    {"headers":{"content-Type":"application/json"},method:"DELETE",mode:"cors",body:JSON.stringify(data)})
     if(response.status === 401){

     }else{
    response.json().then((response,failure)=>{
      //  setComments(response);
    });
  }
   } 

   
    return( 
        <Container>
            <Button size="sm" className="mt-5 rounded-pill" onClick={()=>navigate(-1)}>
        <BsFillArrowLeftCircleFill size={15}/> Back</Button>
            <Row style={styles}> 
            {loadingEvent?<div className="d-flex justify-content-center"><Spinner animation="border" role="status">
           <span className="visually-hidden">Loading...</span>
             </Spinner></div>:
            <Card>
              <Card.Header><i>Click on one image below to choose your choice</i></Card.Header>
              <Card.Body className="h-50">  
              {event.closing_type === "t"?
              <div className="text-muted closing-time"><span><BsFillClockFill size={18}/> {formatClosingTime(event.closing_time)}</span></div>
              :<div className="text-muted closing-time"><span><BsFillClockFill size={18}/> {event.is_closed?"Closed":"Still Open"}</span></div>}          
                <div className="text-center"><h1>{event.event_question}</h1></div>
                {loadingSpinnerOnChoose?<div className="d-flex justify-content-center"><Spinner animation="border" role="status">
           <span className="visually-hidden">Loading...</span>
             </Spinner></div>:<></>}
                 {!event.isStakeClosed && <i>{getTimer(event.closing_time)} left | Decide! </i>}                 
                 <div className="d-sm-inline-flex w-100 justify-content-sm-start">                   
                 {userEventChoice != undefined? userEventChoice.length > 0?
                 <Badge bg="secondary">You predicted</Badge>
               :<></>:<></>}
               {event.isStakeClosed?<Badge bg="danger">Closed</Badge>:<Badge bg="success">Open</Badge>}
               {userEventChoice.is_graded?<Badge>Resolved</Badge>:<></>}
               <div className="ms-3 d-inline pointer" onClick={()=>navigate(`/event/${event.id}/${event.slug}/stakers`)}>{event.stake_count}<BsFillPersonFill size={25}/></div> 
                </div>  
                <div className="w-75 mx-auto d-flex overflow-scroll">                  
                    {event.choices == undefined?`nothing`: event.choices.map(choice=>{
                    return (<div key={choice.id} className="m-3 flex-shrink-0">
                            <EventChoice 
                            onClick={()=>increment(choice,event)} 
                            imageUrl={choice.image_url} 
                            choice={choice}
                            selected={checkSelectedChoice(choice)}
                            />
                            </div>)
                    })}  
                    </div> 
                    </Card.Body>
                    </Card>}                        
            </Row>         
            <Row>
                {/* <CommentsList commentsList={comments} likeClicked={likeClicked}/> */}                             
            </Row>
            <Row>
            <Col lg={6}>
           <div ref={pageEnd}> 
           <CommentSection            
         commentsCount=" "             
        logIn={{
          loginLink: `${process.env.REACT_APP_FRONTEND_URL}/users/login`,
          signupLink: `${process.env.REACT_APP_FRONTEND_URL}/users/register`,          
        }}   
        commentData={comments && comments.results}                           
        currentUser={currentUserId?{
          currentUserId: parseInt(currentUserId),
          currentUserImg: `${process.env.REACT_APP_BACKEND_URL}${userProfile?.photo_url}`,
          currentUserProfile:`${process.env.REACT_APP_FRONTEND_URL}/member/${currentUserId}/${user.username}`,
          currentUserFullName: user.username,          
        }:null}    
            
        onSubmitAction={(data) => postCommentAction(data)} 
        onReplyAction = {(data) => {replyCommentAction(data)}}   
        onDeleteAction = {(data)=>deleteCommentAction(data)}  
        onEditAction = {(data)=>updateCommentAction(data)} 
        currentData={(data) => {
          console.log('current data', data)
        }}
        
      />
      {loadingExtraComments?<div className="d-flex justify-content-center"><Spinner animation="border" role="status">
           <span className="visually-hidden">Loading...</span>
             </Spinner></div>:<></>}    
        {comments.next != null?
       <Button variant="primary" onClick={()=>loadMore()}>Load more</Button>:<></>}
      </div></Col><Col></Col>
            </Row> 
            <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Please log in to Play</Modal.Title>
        </Modal.Header>
        <Modal.Body>          
          <Button onClick={()=>navigate("/users/login")}>Log in</Button>
          <Link to={"/users/register"}>Register</Link>
        </Modal.Body>       
      </Modal>          
        </Container>
    )
      }

export default EventDetail