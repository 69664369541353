import React,{useState,useEffect} from "react"
import { Card } from "react-bootstrap"
import { Image } from "react-bootstrap/Image";
import {Link,BrowserRouter as Router} from "react-router-dom";
import EventView from "./EventView";
import PlayableEventView from "./PlayableEventView";

/* i passed the poll id as a prop down to its child component eventList, so
the the child component can use the id to fetch events that belong to the 
specific poll */
const EventList = ({id})=>{
  const [events,setEvents] = useState([])

  useEffect(()=>{
    fetchEventsByPoll(id)
  },[events.length])

      async function fetchEventsByPoll(id){
        const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/events?poll=${id}`)
        data.json().then(response=>{
            console.log(response)
            setEvents(response)
        })
    }

  return (<div>
    {events.map((event)=>{
      return <EventView key={event.id} event={event}/>
    })}
  </div>       
 
    )
}

export default EventList