import React,{useState,useEffect} from "react";
import { Container,Row,Col,Spinner,Alert } from "react-bootstrap";
import {useSelector} from "react-redux";
import { useRevalidator } from "react-router-dom";


const Admin = ()=>{
  const [choices,setChoice] = useState([]);
  const [competitions,setCompetitions] = useState([]);
  const [seasons,setSeasons] = useState([]);

  const [loadingSpinner,setLoadingSpinner] = useState(false);
    const [show,setShow] = useState(false);

  const [eventsList,setEventsList] = useState([]) 

  const userId = useSelector((state)=>state.auth.value.user.id);
  const token = useSelector((state)=>state.auth.value.token);

  const [formFields,setFormFields] = useState([
   {title:"poll",competition:"",created_by:userId,season:"",relevance_date:""}])

  
  //console.log("user->",userId);

  useEffect(()=>{
    //fetchEvents()
    fetchCompetitions()
    fetchSeasons()
  },[])

  useEffect(()=>{
   let data = [...formFields]
   if(data.length >0 && competitions.length > 0){
   //console.log("data->",data[data.length-1].competition,"competition->",competitions[0]);
   data[data.length-1].competition = competitions[0].id
   setFormFields(data)
   }
  },[competitions])

  useEffect(()=>{
   let data = [...formFields]
   if(data.length >0 && seasons.length > 0){
   //console.log("data->",data[data.length-1].season,"competition->",seasons[0]);
   data[data.length-1].season = seasons[0].id
   setFormFields(data)
   }
  },[seasons])


  async function fetchCompetitions(){
   const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/competitions`)
   data.json().then((res)=>{
      setCompetitions(res)
      console.log("competitions ->",res);
   })  
  }

  async function fetchSeasons(){
   const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/seasons`)
   data.json().then((res)=>{
      setSeasons(res)
      console.log(res)
   })  
  }


 function newEventClicked(){
   setEventsList([...eventsList,{type:"match",
   event_question:"",
   closing_time:"",
   is_gradeble:true,
   is_resolved:false}])
   
 }
 function addChoiceClicked(){
    setChoice([...choices,{"name":"Barcelona","points":1.0}])
 } 

function removedClicked(e){
 console.log(e);
}

function handleFormChange(event,index){

   let data = [...formFields];
   data[index][event.target.name] = event.target.value;
   setFormFields(data);
 // console.log(event.target.name,"->",index,"->",event.target.value);
}


const addFields = ()=>{   
  let object = {title:"poll",competition:"",created_by:userId,season:"",relevance_date:""};
   if(competitions.length > 0 && seasons.length > 0){
   object.competition = competitions[0].id;
   object.season = seasons[0].id;
  } 
  setFormFields([...formFields,object])
}

const removeFields = (index)=>{
   let data = [...formFields];
   data.splice(index,1);
 setFormFields(data);
}

const submit = async (e)=>{
   e.preventDefault();  
   setLoadingSpinner(true);
   setShow(false);
 
    const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/`,
   {method:"POST",
   body:JSON.stringify(formFields),
   headers:{'Content-Type':"application/json","Authorization":"Bearer "+token}})
   setLoadingSpinner(false);
   if(data.status === 200){
      setShow(true);
   data.json().then((res)=>{
     // console.log(res)
   })  
  }
}

 return ( 
 <Container>
    <h1>Admin</h1>
    <Row>
        <Col md="6">  
         <form method="post" onSubmit={submit}>          
         {formFields.map((form,index) =>{
            return (<div key={index}><div><label for="title">title</label>
            <input name="title" type="text" onChange={e => handleFormChange(e,index)} value={form.title}/></div>
            <div><label for="title">Created by</label>  
             </div>  
          
            <div><label for="competition">Competition</label>
            <select name="competition" onChange={e => handleFormChange(e,index)}>
             {competitions.length > 0?
                competitions.map(item =>{      
                return (<option value={item.id}>{item.name}</option>)
             })   
             :<></>}
             </select></div>
          
             <div><label for="season">Season</label>
            <select name="season" onChange={e => handleFormChange(e,index)}>
             {seasons.length > 0?
                seasons.map(item =>{      
                return (<option value={item.id}>{item.title}</option>)
             })   
             :<></>}
             </select>
            </div>
          
            <div><label for="relevance_date">Relevance Date</label>
            <input name="relevance_date" type="date" onChange={e => handleFormChange(e,index)} value={form.relevance_date}/>
            </div>
            <button onClick={()=>removeFields(index)}>Remove</button>
            </div>)
         })}  
          <button onClick={addFields}>Add Poll</button>               
         <button type="submit">Submit</button> 
         </form>  
         {loadingSpinner?<div className="d-flex justify-content-center"><Spinner animation="border" role="status">
           <span className="visually-hidden">Loading...</span>
             </Spinner></div>:<></>}
             {show?
                <Alert variant="success">
                    polls created
                  </Alert>:<></>
             }     
        </Col>
         </Row>  
 </Container>)
}

export default Admin