import React,{useState,useEffect} from "react";
import EventList from "../events/EventList";
import {Spinner,Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsCalendarEvent } from "react-icons/bs";
import PlayableEventList from "../events/PlayableEventList";
import "./poll.css";

const Poll = ({compId,slug})=>{
    const [polls,setPolls] = useState({results:[]})
    const [loadingPolls,setLoadingPolls] = useState(false)          

    useEffect(()=>{        
       fetchPolls()
    },[])

    async function fetchPolls(){ 
        setLoadingPolls(true)       
        const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events?compId=${compId}&data=truncate`)        
        data.json().then(response=>{
            setPolls(response)
            setLoadingPolls(false) 
            console.log("polls->",response)            
        })        
    }

    function pollRelevantDate(dateString){        
        const dayArray = ["Sunday","Monday","Tuesday","Wednesday","Thursday","friday","saturday"];
        const monthArray= ["Jan","Feb","Mar","April","May","June","July","Aug","Sep","Oct","Nov","Dec"];
        const pollDate = new Date(dateString)
        const day = dayArray[pollDate.getUTCDay()]
        const dateNo = pollDate.getUTCDate()
        const month = monthArray[pollDate.getUTCMonth()]
        const year = pollDate.getUTCFullYear()      
        
        console.log("date ",pollDate.getTime())
       
        const date = new Date()
        console.log(year,"date",dateNo - date.getUTCDate())
        if(dateNo === date.getUTCDate() && pollDate.getUTCMonth() === date.getUTCMonth() && year === date.getUTCFullYear()){            
           return "today";
        }else if(dateNo - date.getUTCDate() === 1 && pollDate.getUTCMonth() === date.getUTCMonth() && year === date.getUTCFullYear()){
            return "tommorrow";
        }else{
            return `${day} ${dateNo} ${month} ${year} `
        }       
       }

    return(
       <div>

{loadingPolls?<div className="d-flex justify-content-center"><Spinner animation="border" role="status">
           <span className="visually-hidden">Loading...</span>
             </Spinner></div>:
polls.results.length > 0?
        polls.results.map((poll,index)=>{
            return (<div key={poll.id} >
                <Accordion defaultActiveKey={index}>
                <Accordion.Item eventKey={index}>
                <Accordion.Header className="playable-event-accordion-header"><span><BsCalendarEvent size={15}/>  {" "+pollRelevantDate(poll.relevance_date)}</span></Accordion.Header>
                <Accordion.Body>
                <PlayableEventList id={poll.id} compId={undefined}/>
                </Accordion.Body>
                </Accordion.Item>
                </Accordion>   
                {/* after the last poll in list show season-timed events */}             
                {index === polls.results.length-1 ?
                <div>
                   <Accordion defaultActiveKey={index}>
                   <Accordion.Item eventKey={index}>
                   <Accordion.Header className="playable-event-accordion-header"><span><BsCalendarEvent size={15}/> Other Events</span></Accordion.Header>
                   <Accordion.Body>
                   <PlayableEventList id={undefined} compId={poll.competition}/>
                   </Accordion.Body>
                   </Accordion.Item>
                   </Accordion> 
                   <div className="text-center mw-100" >
                    <Link className="text-decoration-none" to={`/competition/${compId}/${slug}`}>All Events...</Link>
                    </div>
                   </div>
                    :
                <></>}
                </div>)         
        })
        
        :         
        <div><i className="ps-1 text-muted">No events closing today</i> 
         <Accordion className="mt-3">
                   <Accordion.Item>
                   <Accordion.Header className="playable-event-accordion-header"><span><BsCalendarEvent size={15}/> Other Events</span></Accordion.Header>
                   <Accordion.Body>
                   <PlayableEventList id={undefined} compId={compId}/>
                   </Accordion.Body>
                   </Accordion.Item>
                   </Accordion> 
        <div className="text-center mw-100" >
            <Link className="text-decoration-none" to={`/competition/${compId}/${slug}`}>All Events...</Link>
            </div></div>    
       }
       </div>
    )
}

export default Poll