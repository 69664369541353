import React,{useState,useEffect,useRef,useCallback} from "react"
import { useParams,useNavigate,useLocation,} from "react-router-dom"
import { Modal,Form,Button,Container,Card,Row,Col } from "react-bootstrap";
import StakeHistoryView from "../user-stake-history/StakeHistoryView";
import {BsFillArrowLeftCircleFill} from "react-icons/bs"
import { useMemberProfileHook } from "./useMemberProfileHook";

const MemberProfile = ()=>{
  const [member,setMember] = useState({});  
  const [pageNumber,setPageNumber] = useState(1)

  
  const pageEnd = useRef()

  const navigate = useNavigate()
  const {id} = useParams()

  const total_point = localStorage.getItem("points");
  const userid = localStorage.getItem("uid");  
 
  const [events,setEvents] = useState([])
  const [choices,setChoices] = useState({})

      //modal settings
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);  

   useEffect(()=>{
    fetchMemberData()    
    //fetchEvents()    
   },[])


   const {hasMore,memberPlayHistory,loadingSpinner} = useMemberProfileHook(pageNumber,id)
   const observer = useRef()

   const lastElement = useCallback((element)=>{
   
    if(loadingSpinner){ return}
    if(observer.current){observer.current.disconnect()}
     observer.current = new IntersectionObserver(entries=>{
      entries.forEach(entry=>{
        console.log("hasmore is",hasMore)
        if(entry.isIntersecting && hasMore){
          setPageNumber(prevPage=>prevPage+1)
          
        }
      })
    
     })
     if(element){observer.current.observe(element)}
   },[loadingSpinner,hasMore])

   const slug = {
    id:id,
    slug_title:"bret"
   }
   async function fetchMemberData(){
    const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/userprofile/member/${id}`)
       if(data.status === 404){
        navigate("/not-found")
       }else{
        data.json().then((response,reject)=>{
            setMember(response)
            console.log(response)
    })
  }
   }  


  async function fetchEvents(){
    const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/events`)
    data.json().then((response,reject)=>{
        setEvents(response)
        console.log(response)
})
  }

   function handleSelect(e){
    const value = e.target.options[e.target.selectedIndex].value;
    const text = e.target.options[e.target.selectedIndex].text
    console.log(value,text);
    if(events != undefined){
        const data = events.filter(event=>event.id == value)        
        setChoices(data[0].choices)
   }
   }

  async function handleWagerSubmit(e){
    e.preventDefault()
    const requestBody = {
        creator:userid,
        target:member.user.id,
        event:e.target[0].value,
        creator_choice:e.target[1].value,
        creator_wager:e.target[2].value,
        minimum_wage:e.target[3].value,
        status:0
    }
    
   console.log(requestBody)
     const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/wager`,{
        method:"POST",             
        mode:"cors",
        headers:{"Content-Type":"application/json"},
        body: JSON.stringify(requestBody)
     })
   
    data.json().then((response,reject)=>{
        console.log(response)
        setShow(false);
    }).catch(dta=>{
      console.log("jjjj",dta)
    }) 
  
  }

    return (<Container>
      <Button size="sm" className="mt-5 rounded-pill" onClick={()=>navigate(-1)}>
        <BsFillArrowLeftCircleFill size={15}/> Back</Button>
      <Row>
        <Col md={4} className="mb-3">
        {member.user && <Card className="mt-3"><div className="text-center">
        <img className="img-thumbnail mt-3" width="150" height="150" src={`${process.env.REACT_APP_BACKEND_URL}${member.photo_url}`}/>
        <h1>{member.user.username}</h1></div>
        {/* {userid != member.user.id?<Button variant="primary" className="position-relative end-0" onClick={handleShow}>Request Wager</Button>:<></>} */}
        
        {member.stake_data && <div><div className="d-flex w-75 justify-content-around">
          <div><span className="fw-bold text-muted">Rank</span><p>{member.stake_data.rank}</p></div>
          <div><span className="fw-bold text-muted">Total Plays</span><p>{member.stake_data.total_stakes}</p></div>
        </div>
        <div className="d-flex w-75 justify-content-around">
          <div><span className="fw-bold text-muted">Wins</span><p>{member.stake_data.stakes_won}</p></div>
          <div><span className="fw-bold text-muted">Loses</span><p>{member.stake_data.stakes_lost}</p></div>          
        </div></div>}
      </Card>}
      <Card className="mt-3 p-2">
        <h3>Status</h3>
        <p>{member.bio}</p>
      </Card>
      </Col>

      <Col md={8} className="mt-3" ref={pageEnd}>
      <div className="border-bottom">
      <h3>Play History</h3>
      </div>
        {memberPlayHistory.results.map((stake,index)=>{
          if(index+1 === memberPlayHistory.results.length ){
          return <div ref={lastElement}><StakeHistoryView stake={stake}/></div>}
          else{
            return <div><StakeHistoryView stake={stake}/></div>
          }
        })}
        <hr/>
      </Col>


      {member.user &&
      <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create a new Wager Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>      
      <Form onSubmit={handleWagerSubmit}>
       <Form.Text>Target User: {member.user.username}</Form.Text>{" "}  
       <Form.Text>total points: {member.stake_data.total_points}</Form.Text><br/>
           
        <Form.Label>Choose a event</Form.Label>
        <Form.Select name="event" onChange={handleSelect}>
        {events.map((event)=>{
                 return <option value={event.id}>{event.event_question}</option>
                })} 
        </Form.Select>      
            
        <Form.Label>Choose a choice</Form.Label>
        <Form.Select name="choice">
        {choices.length > 0 ?    
            choices.map((choice)=>{
                 return <option value={choice.id}>{choice.choice_text}</option>
                }) 
            :<></>}
        </Form.Select>
        <Form.Text className="text-muted d-block">
          Your choice | the target will select remaining choices on the event
        </Form.Text>

        <Form.Label>Wager Amount | Your stake for this event</Form.Label>
        <Form.Control type="number" placeholder="1" min="1" max={total_point} name="wagerAmount"/>       

        <Form.Label htmlFor="minimumWage">Minimum Target Wager</Form.Label>
        <Form.Control type="number" placeholder="1" min="1" max={member.stake_data.total_points} name="minimumWage"/> 
        <Form.Text className="text-muted d-block">
          how much you want other user to stake?
        </Form.Text>

        <Button className="mt-1" variant="primary" type="submit">Send Wager</Button>
        </Form>
      </Modal.Body>
      <Modal.Footer>        
      </Modal.Footer>
    </Modal>}
    </Row>
    </Container>)
}

export default MemberProfile