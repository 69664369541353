import React from "react"
import Card from "react-bootstrap/Card"
import { useNavigate} from "react-router-dom"
import { username_truncate } from "../utils/users_utils"

const MembersView = (props)=>{
 
const navigate = useNavigate()

    return (<Card className="mb-4" style={{ width: '10rem' }}>      
    <Card.Img variant="top" src={props.photo}/>
    <Card.Body>
      <Card.Title><div onClick={()=>navigate("/member/"+props.member.user.id+"/"+props.member.user.slug)}><span className="link">{username_truncate(8,props.member.user.username)}</span></div></Card.Title>
      <Card.Text> 
      </Card.Text>
    </Card.Body>    
  </Card>)
}

export default MembersView