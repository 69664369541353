import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    token:"",
    user:{
        bio:"",
        favourite_team:"",
        id:0,
        photo_url:"",
        stake_data:{
            total_stakes:0,
            stakes_won:0,
            stakes_lost:0,
            total_points:"",
            rank:0
        },
        user:{id:0,
            first_name:"",
            last_name:"",
            username:"",
            email:"",
            is_staff:"",
            date_joined:"",
            last_login:""},
    },    
    token_type:"",
    expires_in:"",
    isLoggedIn:false
}

const authSlice = createSlice({
    name:"user",
    initialState:{value:initialState},
    reducers:{
        login:(state,action)=>{
            state.value = {...action.payload,isLoggedIn:true}
        },
        logout:(state,action)=>{
            state.value = initialState
        }
    }
})
const authReducer = authSlice.reducer;
export const {login,logout} = authSlice.actions;
export default authReducer;

