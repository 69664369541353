import React,{useState,useEffect,useRef,useCallback} from "react"
import MembersView from "./MembersView"
import Card from "react-bootstrap/Card"
import { Link } from "react-router-dom"
import { Container,Row,Col,Spinner,Button,Form} from "react-bootstrap"
import {BsFillArrowLeftCircleFill} from "react-icons/bs"
import { useNavigate } from "react-router-dom"
import { useMembersHook } from "./useMembersHook"


import "./members.css"

const Member = ()=>{
   const [users,setUsers] = useState([])
   const [pageNumber,setPageNumber] = useState(1)
 const [searchText, setSearchText] = useState();
   
   const pageEnd = useRef()

   const navigate = useNavigate()

   const {hasMore,membersData,loadingSpinner} = useMembersHook(searchText,pageNumber)

 const observer = useRef()

 const lastElement = useCallback((element)=>{
 
  if(loadingSpinner){ return}
  if(observer.current){observer.current.disconnect()}
   observer.current = new IntersectionObserver(entries=>{
    entries.forEach(entry=>{
      console.log("hasmore is",hasMore)
      if(entry.isIntersecting && hasMore){
        setPageNumber(prevPage=>prevPage+1)        
      }
    })
  
   })
   if(element){observer.current.observe(element)}
 },[loadingSpinner,hasMore])

   async function searchUsers(event){    
     setSearchText(event.target.value)
     setPageNumber(1)   
   }

    return (<Container>
         <Button size="sm" className="mt-3 rounded-pill" onClick={()=>navigate(-1)}>
        <BsFillArrowLeftCircleFill size={15}/> Back</Button>      
       <Row className="mt-2">
        <div className="border-bottom p-3">
        <h1>Members</h1>
        </div>
        <div>
       <Form>
      <Form.Group className="mb-3" controlId="formBasicSearch">        
        <Form.Control type="text" placeholder="username" onChange={(event)=>searchUsers(event)} />      
      </Form.Group>      
    </Form> 
    </div>
                 
        <div ref={pageEnd} className="mt-3">
        <Row>        
        {membersData.results.length > 0?            
        membersData.results.map((user,index)=>{
           if(index+1 === membersData.results.length){return <Col key={user.id} ref={lastElement}><MembersView member={user} photo={`${process.env.REACT_APP_BACKEND_URL}${user.photo_url}`}/></Col>}
           else{
            return <Col  key={user.id}><MembersView member={user} photo={`${process.env.REACT_APP_BACKEND_URL}${user.photo_url}`}/></Col>
          }
           }):<span className="text-muted">No members found</span>}        
       </Row>
       {loadingSpinner?<div className="d-flex justify-content-center"><Spinner animation="border" role="status">
           <span className="visually-hidden">Loading...</span>
             </Spinner></div>:<></>}
       <hr/>
       </div>
       </Row>
        
    </Container>)
}

export default Member