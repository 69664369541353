import React,{useEffect,useState} from "react"
import { useParams,useNavigate } from "react-router-dom"
import { Container,Row,Col,Alert,Button} from "react-bootstrap"

const UserActivate = ()=>{
    const [loadingSpinner,setLoadingSpinner] = useState()
    const [showError,setShowError] = useState(false)

    const navigate = useNavigate()

    const {uid} = useParams()
    const {token} = useParams()    

    console.log(uid," ",token)

    useEffect(()=>{
      requestUserActivate()
    },[])

    async function requestUserActivate(){
        setLoadingSpinner(true)
        const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/activate/${uid}/${token}`,{
            method:"POST",})
        if (data.status === 400){
            setShowError(true)
        }else if(data.status === 201){
            setShowError(false)
        }               
    }

    return (<Container>
       <Row className="mt-5">
        <Col md={12}>
          {showError?<Alert key={"danger"} variant={"danger"}>
        Error Occured, Link might be bad!
        </Alert>:  
         <div>   
        <Alert key={"primary"} variant={"primary"}>
        Registration Successful!
        </Alert>
        <Button variant={"primary"} onClick={()=>navigate("/users/login")}>Go to Login page</Button>
        </div>}
        </Col>
       </Row>
    </Container>)
}

export default UserActivate