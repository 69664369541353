import React from "react";
import "./staker.css";

const Staker = ({staker})=>{
  
    return (
        
        <div className="d-flex">
          <div className="flex-shrink">
        <img class="mr-3" className="img-thumbnail" width={80} height={80} src={`${process.env.REACT_APP_BACKEND_URL}${staker.profile.photo_url}`} alt="Generic placeholder image"/>
        </div>
        <div className="flex-grow-1 ms-3">
         <div class="mt-0 fw-bold">{staker.user.username}</div>
         <div>Played At: {new Date(staker.staked_at).toLocaleString()}</div>        
        <div>Choice: {staker.event.isStakeClosed?staker.choice.choice_text:<i className="text-muted">will be visible when event closes</i>}</div>
     </div>
     </div>     
       )
}
export default Staker