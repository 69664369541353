import React,{useEffect,useState} from "react";
import Button from "react-bootstrap/Button"
import {Spinner} from "react-bootstrap";
import { useSelector } from "react-redux";

const Grader = () =>{
    const [resultdata,setData] = useState([])
    const [wagers,setWagers] = useState([])
    const [loadingSpinner,setLoadingSpinner] = useState(true)

    const token = useSelector(state=>state.auth.value.token)

    useEffect(()=>{
        getUserEventChoice()
        //fetchConfirmedWagers()
    },[])

    async function getUserEventChoice(){  
         const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/grader`,{
            headers:{"Content-Type":"application/json","Authorization":"Bearer "+token}
         })
         setLoadingSpinner(false)
       await data.json().then((response,reject)=>{
            setData(response);
            console.log(response);
        })        
    }

    async function fetchConfirmedWagers(){
        const data = await fetch(`http://localhost:8000/api/events/wager-grader`,{
            headers:{"Content-Type":"application/json","Authorization":"Bearer "+token}
        })
        data.json().then((response,reject)=>{
            setWagers(response)
            console.log(response)
    })
    }

    async function gradeUser(requestBody){        

         const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/grader`,{
            method:"POST",             
            mode:"cors",
            headers:{"Content-Type":"application/json","Authorization":"Bearer "+token},
            body: JSON.stringify(requestBody)
         }) 

         data.json().then((response,reject)=>{
            setData(response)
         })
        console.log(requestBody)
    }
    /* return winner between creator and target
    and null if no winner */
    function getWinnerWager(wager){
       if(wager.creator_choice.correct_choice){
        return wager.creator
       }
       else if(wager.target_choice.correct){
        return wager.target
       }else{
        return null
       }
    }

    async function sendBulkGrades(){  
        setLoadingSpinner(true)      
        const grades = []
       
        resultdata.forEach((item)=>{
            let win=0
            let lost=0
            if(item.is_win){
              win=1
            }else{
              lost=1
            }
            grades.push({"user_event_choice":item.id,"won":win,"lost":lost})
        })
        console.log(grades)
      const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/grader-bulk`,{
        method:"POST",             
        mode:"cors",
        headers:{"Content-Type":"application/json","Authorization":"Bearer "+token},
        body: JSON.stringify(grades)
     })

     setLoadingSpinner(false)

    data.json().then((response,reject)=>{
        setData(response)
    })    
    }

    async function sendGrade(wager){
        const winner = getWinnerWager(wager)
       /* merge the winner data along with the current wager index */
       const requestBody = {
        ...wager,
        creator:wager.creator.id,//using id instead of object for the server serializer
        target:wager.target.id,
        creator_choice:wager.creator_choice.id,
        target_choice:wager.target_choice.id,
        event:wager.event.id,
        winner:winner.id,
        is_graded:true
       }
       console.log(requestBody)
   /*   const data = await fetch(`http://localhost:8000/polls/wager-grader`,{
        method:"POST",             
        mode:"cors",
        headers:{"Content-Type":"application/json"},
        body: JSON.stringify(requestBody)
     })

    data.json().then((response,reject)=>{
        console.log(response)
    })  */
    }

    return (<div>
    <table>
        <h2>Event Grader</h2>
        <tr><th>user</th><th>event</th><th>choice</th><th>Correct</th></tr>
    {resultdata.length > 0?    
    resultdata.map((item)=>{
        return (<tr>
            <td>{item.user.username}</td>
            <td>{item.event.event_question}</td>
            <td>{item.choice.choice.choice_text}</td>
            <td>{item.choice.correct_choice?<>true</>:<>false</>}</td>
            <td><button onClick={() => gradeUser({"user_event_choice":item.id,"won":1,lost:0})}>Win</button></td>
            <td><button onClick={() => gradeUser({"user_event_choice":item.id,"won":0,lost:1})}>Lose</button></td>
            </tr>)
    }):<></>}
    </table>
    {loadingSpinner?<div className="d-flex justify-content-center"><Spinner animation="border" role="status">
           <span className="visually-hidden">Loading...</span>
             </Spinner></div>:<></>}
    {resultdata.length > 0?<Button onClick={()=>sendBulkGrades()}>Grade</Button>:<></>}
    <table>
        <h2>Wager Grader</h2>
        <tr>
            <th>Event</th>
            <th>Creator</th>
            <th>Target</th>
            <th>Creator choice</th>
            <th>Target choice</th>
            <th>Creator wager</th>
            <th>Target wager</th>
            <th>Winner</th>            
            <th></th>
            </tr>
            {wagers.length > 0?
        wagers.map((wager)=>{
            return (<tr>
                <td>{wager.event.event_question}</td>
                <td>{wager.creator.username}</td>
                <td>{wager.target.username}</td>
                <td>{wager.creator_choice.choice_text}</td>
                <td>{wager.target_choice.choice_text}</td>
                <td>{wager.creator_wager}</td>
                <td>{wager.target_wager}</td>  
                {getWinnerWager(wager) != null?
                <td>{getWinnerWager(wager).username}</td>:
                <td></td>
                }             
                {getWinnerWager != null?
                <button onClick={()=>sendGrade(wager)}>Grade</button>
                :<></>}
                
                </tr>)
        }):<></>}  
        
    </table>
    
    </div>)
}

export default Grader