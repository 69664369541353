import React,{useState,useEffect,useRef,useCallback} from "react"
import { useSelector } from "react-redux";
import {Container,Row,Col,Button} from "react-bootstrap";
import StakeHistoryView from "./StakeHistoryView";
import {BsFillArrowLeftCircleFill} from "react-icons/bs"
import { useNavigate } from "react-router-dom";
import { useUserPlayHistoryHook } from "./useUserPlayHistoryHook";

const UserStakeHistory = ()=>{ 
  const [pageNumber,setPageNumber] = useState(1)

  const user = useSelector((state)=>state.auth.value.user)
  const pageEnd = useRef()

  const navigate = useNavigate()

  const {loadingSpinner,error,playerData,hasMore} = useUserPlayHistoryHook(pageNumber,user.id)

  const observer = useRef()

  const lastElement = useCallback((element)=>{
  console.log(element)
   if(loadingSpinner){ return}
   if(observer.current){observer.current.disconnect()}
    observer.current = new IntersectionObserver(entries=>{
     entries.forEach(entry=>{      
       if(entry.isIntersecting && hasMore){
         setPageNumber(prevPage=>prevPage+1)         
       }
     })
   
    })
    if(element){observer.current.observe(element)}
  },[loadingSpinner,hasMore])


   return(
    <Container>  
      <Button size="sm" className="mt-5 rounded-pill" onClick={()=>navigate(-1)}>
        <BsFillArrowLeftCircleFill size={15}/> Back</Button>  
      <Row className="mt-3">
        <Col md={8} ref={pageEnd}>
        <div className="mt-3 border-bottom">
        <h1>Play History</h1>
        </div>
      {playerData.results.map((stake,index)=>{
        if(index+1 === playerData.results.length){
        return(<div ref={lastElement} key={stake.id}><StakeHistoryView stake={stake}/></div>)}else{return(<div key={stake.id}><StakeHistoryView stake={stake}/></div>)}
      })}
      <hr/>
      </Col>
      <Col md={4}>
        <h1 className="lead">ads</h1>
      </Col>
    </Row>
    </Container>)
  }      
  
 
  export default UserStakeHistory