import React from "react"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux";
import {BsFillGeoAltFill} from "react-icons/bs"

import "./footer.css";
const Footer = ()=>{
  const isLoggedIn = useSelector((state)=>state.auth.value.isLoggedIn); 

    return(<div className="footer"><footer className="footer-body">
    <div className="copyright-text" >     
      <span className="mb-3 mb-md-0 text-muted">&copy; 2023 pointsrank.com</span>       
    </div>
    <div>
    <ul className="nav list-unstyled mt-1 links">
      <li className="ms-3 text-muted"> <Link className="text-muted" to={"/"}>Home</Link></li>
      <li className="ms-3 text-muted"> <Link className="text-muted" to={"/contact"}>Contact Us</Link></li>
      <li className="ms-3 text-muted"> <Link className="text-muted" to={"/privacy-policy"}>Privacy Policy</Link></li>
      <li className="ms-3 text-muted"> <Link className="text-muted" to={"/terms"}>Terms</Link></li>
      {isLoggedIn?<></>:<li className="ms-3 text-muted"> <Link className="text-muted" to={"/users/login"}>Login</Link></li>}
      <li className="ms-3 mt-1 text-muted"><BsFillGeoAltFill/>The Gambia</li>
    </ul>
    </div>
  </footer></div>)
}

export default Footer