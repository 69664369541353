import React,{useState} from "react"
import {Row,Col,Container,Button,Form,Spinner,Alert} from "react-bootstrap"

const ForgotPassword = ()=>{
    const [loadingSpinner,setLoadingSpinner] = useState(false)
    const [message,showMessage] = useState(false)
    const [error,showError] = useState(false)

    async function submit(e){
        e.preventDefault()        
        setLoadingSpinner(true)
        showError(false)
        const requestBody = {
            "email":e.target[0].value
        }
        
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/reset-password`,
        {
        method:"POST",
        mode:"cors",
        body:JSON.stringify(requestBody),
        "headers":{"Content-Type":"application/json"}}) 

        setLoadingSpinner(false)
        
        if(response.status === 200){
         showMessage(true)
        }else if(response.status === 404){
         showError(true)
        }else{ 

            }
        
    }

  
    
    return (<Container>
        <Row>            
            <Col md={6} className="mt-5"> 
            <h1 className="lead">Enter your register email to proceed</h1> 
            {loadingSpinner?<div className="d-flex justify-content-center"><Spinner animation="border" role="status">
           <span className="visually-hidden">Loading...</span>           
             </Spinner></div>:<></>} 
             {message? 
             <Alert key={"primary"} variant={"primary"}>
             Password reset link sent to your email
             </Alert>:<></>}  
             {error?<Alert key={"danger"} variant={"danger"}>
              Email does not exist
             </Alert>:<></>}                      
            <div>
            <Form onSubmit={(e)=>{submit(e)}}>    
<Form.Group className="mb-3" controlId="formBasicEmail">
<Form.Label>Email</Form.Label>
<Form.Control required type="email" placeholder="Enter Email" />

</Form.Group> 

<div>
<Button variant="primary" type="submit" disabled={message}>
Send Email Link
</Button>
</div>

</Form></div>
            </Col>
            <Col>
            </Col>
        </Row>

    </Container>)
}

export default ForgotPassword