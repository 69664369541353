import React,{useCallback, useEffect,useState,useRef} from "react";
import { Container,Col,Row,Form,Button,Badge,ListGroup,Modal,Spinner,Card} from "react-bootstrap";
import {BsFillBellFill} from "react-icons/bs";
import "./profile.css"
import Cropper from 'cropperjs';
import { useSelector,useDispatch } from "react-redux";
import { addUserProfile } from "../../redux/reducers/userProfileSlice";
import {Link} from "react-router-dom"

const Profile = ()=>{
  const [cropModalShow,setCropModalShow] = useState(false)
  const imageInputFormRef = useRef()//to open file dialog after clicking image
  const [loadingImageSubmit,setLoadingImageSubmit] = useState(false)
  const [loadingForm,setLoadingForm] = useState(false)

  const [loadingMessages,setLoadingMessages] = useState(false);

 
  const [cropper,setCropper] = useState(null)//the cropper instance
  const [imageData,setImageData] = useState(null)//the raw image file
  const [profileData,setProfileData] = useState({})
  const [activeUpdate,setActiveUpdate] = useState(false)//to toggle input forms
  const [image, setImage] = useState(null);
  const [croppedImage,setCroppedImage] = useState(null)
  const imageRef = useRef()


  const profile = useSelector((state)=> state.userProfile.value);

  const token = useSelector(state=>state.auth.value.token)
  const dispatch = useDispatch()  
  const [messages,setMessages] = useState([])

  useEffect(()=>{    
     fetchMessages();
  },[])

async function fetchMessages(){
  setLoadingMessages(true);
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/userprofile/user-message`,{method:"GET",mode:"cors",
          headers:{"Content-Type":"application/json","Authorization":"Bearer "+token}});
          setLoadingMessages(false);
          if(response.status === 401){
                    
          }else if(response.status === 200){
            response.json().then((res)=>{              
              setMessages(res);              
            });

          }
}  

const handleCropModalClose = ()=>{
    cropper.destroy()    
   setCropModalShow(false);
}


  const handleOnEntered = ()=>{
    const image = imageRef.current;   

     //the image data will be serialized later with a Base64IMageSerializer
     var reader = new FileReader()
     if(imageData != null){
     //reader.readAsBinaryString(imageData);     
     reader.readAsDataURL(imageData);
     //after the image load assign the data to the userProfileData object
    reader.onload = async function(){         
          image.src = reader.result          
          /* using a useState function here so i can access data 
          in order parts of code e.g button*/
           setCropper(new Cropper(image,{
           aspectRatio:1,
           viewMode: 0,           
           rotatable:false,
           scalable:false,                     
         }))
         
          }  
        }//making sure image data is no null
  }

  function onCropClicked(){
    setLoadingImageSubmit(true)
    if(cropper != null){
       cropper.getCroppedCanvas({width:320,height:320}).toBlob((blob)=>{
        const reader = new FileReader();
        reader.readAsDataURL(blob)
        reader.onload = async ()=>{
           const userProfileData = {
            photo_data:reader.result
           }
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/userprofile/update-photo`,{method:"POST",mode:"cors",
          headers:{"Content-Type":"application/json","Authorization":"Bearer "+token},body:JSON.stringify(userProfileData)});
          setLoadingImageSubmit(false)
          if(response.status === 201){
          response.json().then((response,failure)=>{
            
            setCropModalShow(false) 
            console.log(response);                     
            dispatch(addUserProfile(response));            
          })  
        }//end of if
           
        }
       })
    }
  }
  
  function handleImageChange(c){   
    setCropModalShow(true);    
    const imageData = c.target.files[0];
    setImageData(imageData) 
    } 
         
     async function usernameChange(e){
      e.preventDefault()
      const username = e.target[0].value
      const request = {
        "username":username
      }
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/change-username`,{method:"POST",mode:"cors",
      headers:{"Content-Type":"application/json","Authorization":"Bearer "+token},body:JSON.stringify(request)});
      if(response.status === 201){
        response.json().then((res,failure)=>{          
          dispatch(addUserProfile({...profile,...profile.user,...res}));
          setActiveUpdate(false)
        })         
      }else if(response.status === 422){
        setActiveUpdate(false)
      }
     }
      async function onsubmit(e){
        e.preventDefault()   
        setLoadingForm(true)
        //photo_data:croppedImage,
         let userProfileData = {
         //gender:e.target[0].value,         
         bio:e.target[0].value,
         //dob:e.target[2].value,
         //favourite_team:e.target[3].value,
         //bio:e.target[4].value         
         };
       
        console.log(userProfileData);
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/userprofile/`,{method:"PUT",mode:"cors",
        headers:{"Content-Type":"application/json","Authorization":"Bearer "+token},body:JSON.stringify(userProfileData)});
        setLoadingForm(false)
        if(response.status === 422){
          setActiveUpdate(false)
        }else if(response.status === 201){
        response.json().then((response,failure)=>{
          console.log(response);          
          setProfileData(response);
          dispatch(addUserProfile(response)); 
          setActiveUpdate(false)
        })  
      }else{
        setActiveUpdate(false)
      }    
      }

      
  
    

    return (<Container>
        <Row className="mt-5">
            <Col md={3} >
              <div className="img-container" onClick={()=>imageInputFormRef.current.click()}>
              <img src={`${process.env.REACT_APP_BACKEND_URL}${profile?.photo_url}`} className="rounded-circle img-thumbnail profile-image" width="200" height="300" alt="profile image"/>
              </div>
              {profile?.user &&
              <div>
                {activeUpdate?<Form onSubmit={usernameChange}>
              <div><Form.Control defaultValue={profile?.user.username} type="text"></Form.Control>
              <Button className="d-inline" type="submit">Save</Button></div>       
            </Form>:<div className="fw-bold">{profile?.user.username}</div>}

                  <div><span className="fw-bold">Member since -</span> {new Date(profile?.user.date_joined).toLocaleString()}</div> 
                  <div><Link to="/change-password">Change Password</Link></div>                 
              </div>}
            </Col>
            <Col md={5} className="mt-2"><div className="border-bottom"><h2 className="text-muted">Personal Infomation</h2></div>
            <Form onSubmit={onsubmit}>
            <Form.Group className="mb-3" controlId="formBasicText">

            {/*<Form.Label className="d-block fw-bold">Gender</Form.Label> 
             
             {activeUpdate?<Form.Select aria-label="Default select example">
              <option value="1">Male</option>
              <option value="2">Female</option>             
            </Form.Select>:<div>Male</div>}   */}          
             
            
              <Form.Label className="d-block fw-bold">Write Something</Form.Label>              
              {activeUpdate?<Form.Control type="text" defaultValue={profile?.bio} placeholder="..." />:<div>{profile?.bio}</div>}
              
              {/* <Form.Label className="d-block fw-bold">Date of Birth</Form.Label>
              {activeUpdate?<Form.Control type="date" defaultValue={profile.dob}  placeholder="Choose Date"/>:<div>{profile.dob}</div>} */}

             {/*  <Form.Label className="d-block fw-bold">Favourite Team</Form.Label>
              {activeUpdate? <Form.Control type="text" defaultValue={profile.favourite_team}  placeholder="Team you support" />:<div>{profile.favourite_team}</div>} */}

             {/*  <Form.Label className="d-block fw-bold">About you</Form.Label>              
              {activeUpdate?<Form.Control type="text" defaultValue={profile?.bio}  placeholder="Description"/>:<div>{profile?.bio}</div>} */}

            </Form.Group>  
            {activeUpdate?        
            <span>
              <Button variant="primary" type="submit">
              Submit
            </Button>{" "}
            <Button variant="secondary" 
            onClick={()=>setActiveUpdate(false)}>Cancel</Button>
            {
            loadingForm?<div className="d-flex justify-content-center p-2">
              <Spinner animation="border" role="status">
             <span className="visually-hidden">Loading...</span>
             </Spinner></div>:<></>
             }
             </span>:<div className="d-flex justify-content-end"><Button 
            variant="primary"            
            onClick={()=>setActiveUpdate(true)}>Edit
            </Button></div>}
            
          </Form>
            </Col>
            <Col md={4} className="mt-2">
            <h2 className="text-center text-muted">Your Staking Details</h2>
               
         {profile?.stake_data != undefined?
              <ListGroup>
                <ListGroup.Item className="d-flex justify-content-around fw-bold"><span>Rank</span><span><Badge pill bg="primary">{profile?.stake_data.rank}</Badge></span></ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-around"><span>Total Points</span><span><Badge pill bg="primary">{profile?.stake_data.total_points}</Badge></span></ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-around"><span>Total Plays</span><span><Badge pill bg="primary">{profile?.stake_data.total_stakes}</Badge></span></ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-around"><span>Events Won</span><span><Badge pill bg="success">{profile?.stake_data.stakes_won}</Badge></span></ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-around"><span>Events lost</span><span><Badge pill bg="danger">{profile?.stake_data.stakes_lost}</Badge></span></ListGroup.Item>                
              </ListGroup>:<></>}      
                   
            </Col>
        </Row>
        <Row>
          <Col className="mt-2 mb-2 border-top" md={8}>
          <h4 className="text-muted"><span><BsFillBellFill size={23}/> Messages</span></h4>
          {loadingMessages?<Spinner/>:
          messages.length > 0?
          messages.map((message,index)=>{
          return (<div key={index} className="message-card">     
        <div className="d-flex"><span className="message-subject">{message.subject} |</span>
        <span className="message-subject">{message.date_created}</span>
        </div>        
        <div>
          {message.message}
        </div>     
    </div>)}):<span>No messages</span>}
    </Col>
        </Row>
        <Row>                  
              <Form.Control className="d-none" onChange={handleImageChange} ref={imageInputFormRef} type="file" accept="image/*"/>
              </Row>            
             <Modal onEntered={()=>handleOnEntered()} show={cropModalShow} onHide={handleCropModalClose}>
             <Modal.Header closeButton>
               <Modal.Title>Crop Image</Modal.Title>              
             </Modal.Header>
             <Modal.Body>
              {loadingImageSubmit?
              <div className="d-flex justify-content-center"><Spinner  animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner></div>
              :<></>}
              <Row>
              <Col>
             <div className="h-100 w-100">
               <img className="mw-100 d-block" src="" id="image" ref={imageRef}/>        
             </div>
             </Col>
             </Row>
             </Modal.Body>
             <Modal.Footer>
               <Button variant="secondary" onClick={onCropClicked}>
                 Submit
               </Button>
               <Button variant="primary" onClick={handleCropModalClose}>
                 Cancel
               </Button>
             </Modal.Footer>
           </Modal>
    </Container>)

}

export default Profile