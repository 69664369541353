import React from "react"
import {Container,Row,Col} from "react-bootstrap"

const ContactPage = ()=>{
    return(<Container>
        <Row>
         <Col>
         {/* <h3 className="fw-bold">Contact</h3> */}
         {/* <p>pointsrank2022@gmail.com</p> */}
         <h3 className="fw-bold">Developer</h3>
         <p>ed.sarr7@gmail.com</p>
         {/* <a href="https://www.linkedin.com/in/edrissa-sarr/">My Linkedln</a> */}
         </Col>
         <Col></Col>
        </Row>
    </Container>)
}

export default ContactPage