import React from "react"
import { Card,Badge } from "react-bootstrap"
import {Link,BrowserRouter as Router,useNavigate} from "react-router-dom";
import {BsFillChatRightTextFill,BsFillPersonFill} from "react-icons/bs"
import "./event_choice.css"

/* 
this show the event that includes ct,event_question,comments count and stake count
*/
const EventView = ({event})=>{
  const navigate = useNavigate()
  function commentCountFix(count){
    if(count == 0){
      return `${count}`;
    }else{
      return `${count}+`
    }
  }

  function questionShortened(question){
   if (question != undefined){ 
   if(question.length > 30){
    return question.substring(0,30)+"..."   
   }else{   
    return question   
   }   
  }   
  }    

  return (        
    <Card>
  <Card.Body>  
    <div className="d-flex w-auto mx-auto overflow-scroll">  
    <div className="flex-shrink-0 ms-2 small">CT-{event.closing_time != null?new Date(event.closing_time).toLocaleTimeString():"-"}</div>{' '}
    <div className="fw-bold text-decoration-none flex-shrink-0 ms-2" onClick={()=>navigate(`/event/${event.id}/${event.slug}`)}><span className="link">{questionShortened(event.event_question)}</span></div>{' '}
    <div className="flex-shrink-0 ms-2">
    {event.choices.map((choice,index)=>{
     return <span>{choice.choicePercentage+"%"}{index != event.choices.length-1?"-":<></>}</span> 
    })}  
    </div>  
   
    <div className="text-muted ms-3 flex-shrink-0">{commentCountFix(event.commentCount)} <BsFillChatRightTextFill size={22}/></div>     
    
    <div className="ms-2 flex-shrink-0 pointer" onClick={()=>navigate(`/event/${event.id}/${event.slug}/stakers`)}>{event.stake_count}<BsFillPersonFill size={22}/></div>    
   </div> 
   </Card.Body>
   </Card>
 
    )
}

export default EventView