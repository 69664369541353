import React,{useState,useEffect,useRef,useCallback} from "react";
import EventList from "../events/EventList";
import {Accordion,Spinner} from "react-bootstrap";
import { useAllPollsHook } from "./useAllPollsHook";
import { BsCalendarEvent } from "react-icons/bs";

const AllPolls = ({compId})=>{    
    const [pageNumber,setPageNumber] = useState(1)

    const {loadingSpinner,error,polls,hasMore} = useAllPollsHook(pageNumber,compId)

    const observer = useRef()
  
    const lastElement = useCallback((element)=>{
    console.log(element)
     if(loadingSpinner){ return}
     if(observer.current){observer.current.disconnect()}
      observer.current = new IntersectionObserver(entries=>{
       entries.forEach(entry=>{      
         if(entry.isIntersecting && hasMore){
           setPageNumber(prevPage=>prevPage+1)         
         }
       })
     
      })
      if(element){observer.current.observe(element)}
    },[loadingSpinner,hasMore])   


    function pollRelevantDate(dateString){        
        const dayArray = ["Sunday","Monday","Tuesday","Wednesday","Thursday","friday","saturday"];
        const monthArray= ["Jan","Feb","Mar","April","May","June","July","Aug","Sep","Oct","Nov","Dec"];
        const pollDate = new Date(dateString)
        const day = dayArray[pollDate.getUTCDay()]
        const dateNo = pollDate.getUTCDate()
        const month = monthArray[pollDate.getUTCMonth()]
        const year = pollDate.getUTCFullYear()        
       
        //use UTC date otherwise date might be off by 1 day
        const date = new Date()       
        if(dateNo === date.getUTCDate() && pollDate.getUTCMonth() === date.getUTCMonth() && year === date.getUTCFullYear()){            
           return "today";
        }else if(dateNo - date.getUTCDate() === 1 && pollDate.getUTCMonth() === date.getUTCMonth() && year === date.getUTCFullYear()){
            return "tommorrow";
        }else{
            return `${day} ${dateNo} ${month} ${year} `
        }
       
       }

    return(
       <div>        
{polls.results.length > 0?
        polls.results.map((poll,index)=>{
            if(index+1 === polls.results.length){
            return (<div key={poll.id} ref={lastElement}>
                <Accordion>
                <Accordion.Item eventKey={index}>
                <Accordion.Header><BsCalendarEvent size={20}/> {pollRelevantDate(poll.relevance_date)}</Accordion.Header>
                <Accordion.Body>
                <EventList key={poll.id} id={poll.id}/>
                </Accordion.Body>
                </Accordion.Item>
                </Accordion>           
                </div>)}else{ return <div key={poll.id}>
                <Accordion>
                <Accordion.Item eventKey={index}>
                <Accordion.Header><span><BsCalendarEvent size={20}/> {pollRelevantDate(poll.relevance_date)}</span></Accordion.Header>
                <Accordion.Body>
                <EventList key={poll.id} id={poll.id}/>
                </Accordion.Body>
                </Accordion.Item>
                </Accordion>           
                </div>}         
        }):<p className="p-2">Events to be added soon</p>     
       }
       <hr/>
       </div>
    )
}
export default AllPolls