import React,{useState} from "react"
import { Container,Row,Col, Button,Modal,Form,Spinner } from "react-bootstrap"
import { useSelector } from "react-redux"
import { json, useNavigate } from "react-router-dom"

const AccountSettings = ()=>{
    const [deleteConfirmShow,setShowDeleteModal] = useState(false)
    
    const token = useSelector(state=>state.auth.value.token)
      const navigate = useNavigate()
    return (<Container>
        <Row className="mt-4">
          <div className="mb-3 border-bottom">
            <h1>Account Settings</h1>
            </div>
        </Row>
        <Row className="border-bottom">
           <h1 className="lead">Delete Account?</h1>
           <Button size="lg" variant={"danger"} disabled={true} onClick={()=>setShowDeleteModal(true)}>Delete Your Account?</Button>
        </Row>
         <DeleteConfirmModal show={deleteConfirmShow} onHide={()=>setShowDeleteModal(false)} 
        navigate={navigate}
         cancelClicked={()=>setShowDeleteModal(false)}
        token={token} /> 
    </Container>)
}

function DeleteConfirmModal(props){  
  const [loadingSpinner,setLoadingSpinner] = useState(false)
  const [fieldError,setFieldErrors] = useState([{password:[""]}])

  async function deleteUserClicked(e){
    e.preventDefault()
        setLoadingSpinner(true)
        setFieldErrors([{password:[""]}])
       const requestBody = {
        password:e.target[0].value
      } 
      console.log(requestBody) 
     const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/delete`,
    {headers:{"content-Type":"application/json","Authorization":"Bearer "+props.token},method:"POST",body:JSON.stringify(requestBody)})
    setLoadingSpinner(false)
    if(response.status === 400){
      setFieldErrors([{password:["sorry,wrong password"]}])
    }else if(response.status == 201){
      props.navigate("/users/login")
    }else{
    response.json().then((response,failure)=>{
               
    });   
    }
}
   return (<Modal {...props}>
  <Modal.Header closeButton>
    <Modal.Title>Delete Account</Modal.Title>
  </Modal.Header>

  <Modal.Body>
    <p>Are you sure you want to delete account?</p>
    <Form onSubmit={deleteUserClicked}>
    <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control type="password" required placeholder="Password" />
        <Form.Text  style={{color:"red"}}>{fieldError?.password}</Form.Text>
      </Form.Group>
      <Button variant="danger" type="submit">
      {loadingSpinner?<Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        >
        <span className="visually-hidden">Loading...</span></Spinner>:"Delete"}</Button>  
      </Form>    
  </Modal.Body>

  <Modal.Footer>  
    <Button variant="primary" onClick={()=>props.cancelClicked()}>Cancel</Button>
    
  </Modal.Footer>
</Modal>)
  }

export default AccountSettings