import { useEffect,useState } from "react"


export const useMembersHook = (searchText="",pageNumber)=>{
 const [membersData,setMembersData] = useState({results:[]})
 const [loadingSpinner,setLoadingSpinner] = useState(true)
 const[error,setError] = useState(false)
 const [hasMore,setHasMore] = useState(false)

useEffect(()=>{ 
    membersData.results.length = 0    
}, [searchText.length]) 



async function fetchMembersData(){
    
    setLoadingSpinner(true)
    setError(false)
    const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/userprofile/members?search=${searchText}&page=${pageNumber}`)
    
    data.json().then((response,reject)=>{ 
        setLoadingSpinner(false)        
        setMembersData({...membersData,...response,results:[...membersData.results,...response.results]})
        console.log(response)
        //setStakeData({...stakeData,...response,results:[...response.results]})
        setHasMore(response.next !== null)        
        setLoadingSpinner(false)
        
    }).catch((error)=>{
        setError(true)
    }) 
}

useEffect(()=>{    
 fetchMembersData()
},[searchText,pageNumber])
    return {loadingSpinner,error,membersData,hasMore}
}
