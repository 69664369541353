import React,{useState,useEffect,useRef,useCallback} from "react"
import { Row,Container,Col, Badge,Button,Spinner,Table,DropdownButton,Dropdown } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import {BsFillArrowLeftCircleFill} from "react-icons/bs"
import {useRankingsHook} from "./useRankingsHook"

const Rankings = ()=>{
 //const [stakeData,setStakeData] = useState({results:[]})
 const [pageNumber,setPageNumber] = useState(1)
 const [competitions, setCompetitions] = useState([])
 //const [hasMore,setHasMore] = useState(false)
const [comp,setComp] = useState(0)

const [timeFilter, setTimeFilter] = useState("this_week")

const {hasMore,stakeData,loadingSpinner} = useRankingsHook(comp,pageNumber,timeFilter);
 const navigate = useNavigate()

 const observer = useRef()

 const lastElement = useCallback((element)=>{
 
  if(loadingSpinner){ return}
  if(observer.current){observer.current.disconnect()}
   observer.current = new IntersectionObserver(entries=>{
    entries.forEach(entry=>{
      console.log("hasmore is",hasMore)
      if(entry.isIntersecting && hasMore){
        setPageNumber(prevPage=>prevPage+1)
        
      }
    })
  
   })
   if(element){observer.current.observe(element)}
 },[loadingSpinner,hasMore])
  

 useEffect(()=>{
  fetchCompetitions()
 },[])

 function handleCompetitionChange(compId){
   setComp(compId)
   setPageNumber(1)
 }

 function handleTimeFilterChange(timeFilter){
  setTimeFilter(timeFilter);
  setPageNumber(1)
 }

 async function fetchCompetitions(){  
  const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/competitions?competition`)
  data.json().then(response=>{      
      setCompetitions(response)
      console.log("competitions",response);            
  })
}

    return (
    <div>
       <Container><Button size="sm" className="mt-5 rounded-pill" onClick={()=>navigate(-1)}>
        <BsFillArrowLeftCircleFill size={15}/> Back</Button>
        <Row className="mt-3">
          <Col md={8}>
        <div className="border-bottom d-flex justify-content-around">  
        <h1 className="text-muted">Rankings</h1>
         
         <div className="d-flex justify-content-around">
        <DropdownButton 
        className="mr-2"              
        size="sm"
        variant="secondary"
        title={timeFilter === "all_time"?"All Time":"This Week"}>       
        <Dropdown.Item onClick={()=>handleTimeFilterChange("all_time")}>All Time</Dropdown.Item>
        <Dropdown.Item onClick={()=>handleTimeFilterChange("this_week")}>This Week</Dropdown.Item>    
    </DropdownButton>
        {" "}
        <DropdownButton
        className="ml-2"              
        size="sm"
        variant="secondary"
        title="By Competition">       
       
      <Dropdown.Item onClick={()=>handleCompetitionChange(0)}>All</Dropdown.Item>
      {competitions.length > 0?
        competitions.map((comp)=>{
          return  (<Dropdown.Item key={comp.id} onClick={()=>handleCompetitionChange(comp.id)}>{comp.name}</Dropdown.Item>)
        }):<></>}           
     
      </DropdownButton>
      </div>

        </div>       
        <div>
        <Table responsive="sm" striped border hover>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Username</th>
            <th>Total Plays</th>
            <th>Points Earned</th>
            <th></th>
          </tr>
        </thead>
        <tbody>          
          {stakeData.results != undefined && stakeData.results.length > 0?          
            stakeData.results.map((data,index)=>{
              if(index+1 === stakeData.results.length){
            return(<tr key={data.id} ref={lastElement}>
            <td><Badge className={(index+1) === 1?"bg-primary":"bg-secondary"}>{index+1}</Badge></td>
            <td><img loading="lazy" className="m-2 rounded" width={50} height={50} src={`${process.env.REACT_APP_BACKEND_URL}${data.profile.photo_url}`}/><span className="fw-bold">{data.profile.user.username}</span></td>
            <td>{data.total_stakes}</td>
            <td>{data.total_points}</td> 
            <td><div onClick={()=>navigate(`/member/${data.profile.user.id}/${data.profile.user.slug}`)}><span className="link">View Profile</span></div></td>         
          </tr>)
          }else{return (<tr key={data.id}>
          <td><Badge className={(index+1) === 1?"bg-primary":"bg-secondary"}>{index+1}</Badge></td>
          <td><img loading="lazy" className="m-2 rounded" width={50} height={50} src={`${process.env.REACT_APP_BACKEND_URL}${data.profile.photo_url}`}/><span className="fw-bold">{data.profile.user.username}</span></td>
          <td>{data.total_stakes}</td>
          <td>{data.total_points}</td> 
          <td><div onClick={()=>navigate(`/member/${data.profile.user.id}/${data.profile.user.slug}`)}><span className="link">View Profile</span></div></td>         
        </tr>)}
            }):<></>
            }          
        </tbody>
      </Table>
      {loadingSpinner?<div className="d-flex justify-content-center"><Spinner animation="border" role="status">
           <span className="visually-hidden">Loading...</span>
             </Spinner></div>:<></>}
      <hr/>        
      </div>
      
      </Col>
      <Col md={4}>
       {/* <h1 className="lead">Ads </h1> */}
      </Col>
      </Row></Container>  
    </div>)
}

export default Rankings