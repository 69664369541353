import React,{useState,useEffect} from "react";
import { Card,Spinner } from "react-bootstrap";
import Poll from "../polls/Poll";
import { useParams } from "react-router-dom";
import EventView from "../events/EventView"
import { useNavigate } from "react-router-dom";

const CompetitionInfo = ()=>{

    const {id} = useParams()
    
    const [comp, setCompetition] = useState({})
    const [event,setEvent] = useState({choices:[]})
    const [loadingEvent,setLoadingEvent] = useState(false)
    const [loadingMostComment, setLoadingMostComment] = useState(false)
    const [loadingCompetition,setLoadingCompetition] = useState(false)
   
    const [mostCommentEvent,setMostCommentEvent] = useState({choices:[]})
   


    const navigate = useNavigate()
    
    useEffect(()=>{    
          
        fetchCompetition(); 
        fetchEvent();
        fetchMostCommentEvent()        
      },[])

    async function fetchCompetition(){
        setLoadingCompetition(true)
        const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/competitions/${id}`)
        if(data.status === 404){
            navigate("/not-found")
        }
        data.json().then(response=>{
            setLoadingCompetition(false)
            setCompetition(response)                      
        })
    }
    
    async function fetchEvent(){
        setLoadingEvent(true)
        const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/competitions/${id}/more-info?type=stake`)
        data.json().then(response=>{
            setLoadingEvent(false)
            setEvent(response)                 
        })
    }
    async function fetchMostCommentEvent(){
        setLoadingMostComment(true)
        const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/events/competitions/${id}/more-info?type=comment`)
        data.json().then(response=>{
            setLoadingMostComment(false)
            setMostCommentEvent(response)                      
        })
    }

    
    return(<> 
        {loadingCompetition?<div className="d-flex justify-content-center"><Spinner animation="border" role="status">
           <span className="visually-hidden">Loading...</span>
             </Spinner></div>:     
        comp &&        
           <Card className="mb-3">
                <Card.Header>
                    <img width="32" height="32" alt="-" src={`${process.env.REACT_APP_BACKEND_URL}${comp.logo}`}/><span className="fw-bold">{comp.name}</span>
                </Card.Header>
                <Poll compId={id} slug={comp.slug}/>                
                </Card>
                }
            {loadingEvent?<div className="d-flex justify-content-center"><Spinner animation="border" role="status">
                              <span className="visually-hidden">Loading...</span>
                              </Spinner></div>:   
            event.event_question != "" && <Card className="mb-3">
                <Card.Header>
                 <span className="fw-bold">Most Plays </span>
                </Card.Header> 
              <EventView event={event}/>                              
                </Card> } 
             {loadingMostComment? <div className="d-flex justify-content-center"><Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                            </Spinner></div>:
             mostCommentEvent.event_question != "" && <Card className="mb-3">
                <Card.Header>
                <span className="fw-bold">Most Comments</span>
                </Card.Header> 
              <EventView event={mostCommentEvent}/>                              
                </Card> 
            }
             
            </>)
}

export default CompetitionInfo