import React,{useEffect,useState} from "react"
import {Row,Col,Container,Button,Form,Spinner} from "react-bootstrap"
import { useParams,useNavigate } from "react-router-dom"

const ResetPasswordForm = ()=>{
    const [loadingSpinner,setLoadingSpinner] = useState(false)
    const {uid} = useParams()
    const {token} = useParams()
    const [fieldError,setFieldErrors] = useState([{password:[""]}])

    const navigate = useNavigate()
    
    function validateFields(password,confirm_password){
        if(password !== confirm_password){
          setFieldErrors({password:["Passwords dont match"]});
           setLoadingSpinner(false); 
           return false 
          }else if(!/^(?=.*[0-9])(?=.*)[a-zA-Z0-9*]{6,16}$/.test(password)){
          setFieldErrors({password:["Password is too weak"]});
          setLoadingSpinner(false)
          return false
        }else{
            return true
        }
      }

    async function submit(e){
        e.preventDefault()
        setLoadingSpinner(true)
        setFieldErrors([{password:[""]}])
        const password = e.target[0].value
        const confirm_password = e.target[1].value
        if(!validateFields(password,confirm_password)){
            return
        }
        const requestBody = {
            "password":password,
            "confirm_password":confirm_password
        }
        
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/reset-password/${uid}/${token}`,
        {method:"POST",
        mode:"cors",
        body:JSON.stringify(requestBody),
        "headers":{"Content-Type":"application/json"}})  
        
        setLoadingSpinner(false)
        if(response.status === 201){
          navigate("/users/login")
        }else{        
       await response.json().then((data,error)=>{          
                  
      })
    }
    }

    useEffect(()=>{
       
    },[])
    
    return (<Container>
        <Row>
            <Col>
            <h1>Enter your new password</h1>            
 <Form onSubmit={(e)=>{submit(e)}}>    
<Form.Group className="mb-3" controlId="formBasicPassword">
<Form.Label>New Password</Form.Label>
<Form.Control type="password" placeholder="Enter Password" />
<Form.Text  style={{color:"red"}}>{fieldError?.password}</Form.Text>
</Form.Group> 

<Form.Group className="mb-3" controlId="formBasicPassword">
<Form.Label>Confirm Password</Form.Label>
<Form.Control type="password" placeholder="Enter Password" />
</Form.Group>  

<div className="w-25 d-flex justify-content-around">
<Button variant="primary" type="submit">
{loadingSpinner?<Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        >
        <span className="visually-hidden">Loading...</span></Spinner>:"Reset Password"}
</Button>
</div>

</Form>
            </Col>
        </Row>

    </Container>)
}

export default ResetPasswordForm