import React,{useState} from "react"
import {Row,Col,Container,Button,Form,Spinner} from "react-bootstrap"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import {BsFillArrowLeftCircleFill} from "react-icons/bs"



const ChangePassword = ()=>{
    const [loadingSpinner,setLoadingSpinner] = useState(false)
    const [errors,setErrors] = useState([])
    const [fieldError,setFieldErrors] = useState([{current_password:[""],password:[""]}])
    const token = useSelector(state=>state.auth.value.token)
    const navigate = useNavigate()

    function validateFields(password,confirm_password){
        if(password !== confirm_password){
          setFieldErrors({password:["Passwords dont match"]});
           setLoadingSpinner(false); 
           return false 
          }else if(!/^(?=.*[0-9])(?=.*)[a-zA-Z0-9*]{6,16}$/.test(password)){
          setFieldErrors({password:["Password is too weak"]});
          setLoadingSpinner(false)
          return false
        }else{
            return true
        }
      }

    async function submit(e){
        e.preventDefault()
        const new_pass = e.target[1].value
        const confirm_pass = e.target[2].value
        setLoadingSpinner(true)
        setFieldErrors({current_password:[""],password:[""]})
        if(!validateFields(new_pass,confirm_pass)){
            return;
        }
        
        const requestBody = {
            "current_password":e.target[0].value,
            "new_password":e.target[1].value
        }
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/change-password`,
        {method:"POST",
        mode:"cors",
        body:JSON.stringify(requestBody),
        "headers":{"Content-Type":"application/json","Authorization":"Bearer "+token}})              
        setLoadingSpinner(false)
       if(response.status === 201){
        navigate("/users/login")
       }else if(response.status === 400){
        setFieldErrors({current_password:["sorry, password is wrong"]})
       }else{
       await response.json().then((data,error)=>{        
        console.log(data)    
        //logout user and send to login
      })
    }
    }
    
    return (<Container>
         <Button size="sm" className="mt-3 rounded-pill" onClick={()=>navigate(-1)}>
        <BsFillArrowLeftCircleFill size={15}/> Back</Button> 
        <Row>
            <Col className="mt-3" md={6}>
            <h1 className="text-center fw-bold">Change password</h1>            
            <div>
            <Form onSubmit={(e)=>{submit(e)}}>    
<Form.Group className="mb-3" controlId="formBasicPassword">
<Form.Label>Current Password</Form.Label>
<Form.Control required type="password" placeholder="Enter old password" />
<Form.Text  style={{color:"red"}}>{fieldError?.current_password}</Form.Text>
</Form.Group> 

<Form.Group className="mb-3" controlId="formBasicPassword">
<Form.Label>New Password</Form.Label>
<Form.Control required type="password" placeholder="Enter new password" />
<Form.Text  style={{color:"red"}}>{fieldError?.password}</Form.Text>
</Form.Group>

<Form.Group className="mb-3" controlId="formBasicPassword">
<Form.Label>Confirm New Password</Form.Label>
<Form.Control required type="password" placeholder="Enter new password" />
</Form.Group>

<div>
<Button variant="primary" type="submit">
{loadingSpinner?<Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        >
        <span className="visually-hidden">Loading...</span></Spinner>:"Change Password"}
</Button>
</div>

</Form></div>
            </Col>
            <Col>
            
            </Col>
        </Row>

    </Container>)
}

export default ChangePassword